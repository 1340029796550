import { isOperatorAvailable } from "views/operators/components/OperatorCardGrid";

const mapUserAttributes = (users) => {
  return users?.map((user) => ({
    enabled: user.enabled,
    id: user.id,
    username: user.username,
    firstName: user.firstName,
    lastName: user.lastName,
    credentials: user.credentials,
    location: user.attributes?.location ? user.attributes.location[0] : null,
    number: user.attributes?.number ? user.attributes.number[0] : null,
    availability: user.attributes?.availability
      ? user.attributes.availability[0]
      : isOperatorAvailable.UNAVAILABLE,
    email: user.email,
    groups: user.groups,
  }));
};

export default mapUserAttributes;
