import React, { useState, useEffect } from "react";
import { FormControl, FormErrorMessage } from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import { useTranslation } from "react-i18next";
import API from "api/API";

const inputBgColor = "#EEEEEE";
const nTags = 30;

const CreateDeviceTag = ({ control, errors, isReadOnly }) => {
  const { t } = useTranslation();
  const [tags, setTags] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    setTagOptions(tags.map((tag) => ({ value: tag, label: tag })));
  }, [tags]);

  useEffect(() => {
    const fetchPlants = async () => {
      try {
        const response = await API.getAllTags();
        if (Array.isArray(response.data)) {
          setTags(
            [...new Set(response.data.map((item) => item.name))].slice(-nTags)
          );
        } else {
          setTags([]);
          setError(new Error("Invalid data format"));
        }
      } catch (err) {
        setError(err);
        setTags([]);
      } finally {
        setLoading(false);
      }
    };

    fetchPlants();
  }, []);

  return (
    <FormControl p={"10px"} isInvalid={errors.tags}>
      <Controller
        name="tags"
        control={control}
        // rules={{ required: t("create_device_form.tags_required") }}
        render={({ field }) => (
          <CreatableSelect
            isDisabled={isReadOnly}
            {...field}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "black",
              },
            })}
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: inputBgColor,
              }),
            }}
            options={tagOptions}
            isMulti
            menuPlacement="top"
            onChange={(selected) => {
              field.onChange(selected);
            }}
            placeholder={t("create_device_form.tags")}
          />
        )}
      />
      <FormErrorMessage>{errors.tags && errors.tags.message}</FormErrorMessage>
    </FormControl>
  );
};

export default CreateDeviceTag;
