import React, { useState, useCallback } from "react";
import { Flex, Box, Switch, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const ColumnVisibilityToggle = ({ allColumns, toggleHideColumn }) => {
  const { t } = useTranslation();

  const [columnsState, setColumnsState] = useState(
    allColumns.reduce((acc, column) => {
      acc[column.id] = !column.isHidden;
      return acc;
    }, {})
  );

  const handleToggle = useCallback(
    (id) => {
      setColumnsState((prevState) => {
        const updatedState = { ...prevState, [id]: !prevState[id] };
        toggleHideColumn(id);
        return updatedState;
      });
    },
    [toggleHideColumn]
  );

  return (
    <Box p="20px" border="2px" m={"10px"}>
      <Flex direction="column">
        {allColumns.map((column) => (
          <Flex key={column.id} pt="5px">
            <Switch
              isChecked={columnsState[column.id]}
              onChange={() => handleToggle(column.id)}
            />
            <Text pl="5px">{t(`events_list.${column.Header}`)}</Text>
          </Flex>
        ))}
      </Flex>
    </Box>
  );
};

export default ColumnVisibilityToggle;
