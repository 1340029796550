import React, { useMemo, useState } from "react";
import { Flex, Input, Box, Button } from "@chakra-ui/react";
import ReactSelect from "react-select";
import { useTranslation } from "react-i18next";

const ColumnFilter = ({ columns }) => {
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const { t } = useTranslation();

  const columnOptions = useMemo(
    () =>
      columns.map((column) => ({
        value: column.id,
        label: t(`events_list.${column.Header}`),
      })),
    [columns, t]
  );

  const handleFilterChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    if (selectedColumn && selectedColumn.setFilter) {
      selectedColumn.setFilter(value);
    }
  };

  const handleColumnChange = (selectedOption) => {
    const column = columns.find((col) => col.id === selectedOption.value);
    setSelectedColumn(column);
    setInputValue("");
    if (column && column.setFilter) {
      column.setFilter("");
    }
  };

  const handleReset = () => {
    setInputValue("");
    columns.forEach((col) => {
      col.setFilter("");
    });
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      backgroundColor: "#EEEEEE",
      borderColor: "black",
      boxShadow: "none",
      "&:hover": {
        borderColor: "black",
      },
      menu: (base) => ({
        ...base,
        zIndex: 9999,
        position: "absolute",
      }),
    }),
  };

  return (
    <Box>
      <Flex gap="2" direction="column">
        <Box>{t("column_filter.column")}</Box>
        <ReactSelect
          menuPlacement="auto"
          options={columnOptions}
          onChange={handleColumnChange}
          placeholder={t("column_filter.select_column")}
          styles={customStyles}
        />
        <Box>{t("column_filter.value")}</Box>
        <Input
          value={inputValue}
          focusBorderColor="black"
          backgroundColor={"#EEEEEE"}
          sx={{
            "::placeholder": {
              color: "black",
            },
          }}
          placeholder={t("column_filter.filter")}
          onChange={handleFilterChange}
          width={"100%"}
          maxWidth={"200px"}
        />
      </Flex>
      <Button
        bgColor={"pcr.200"}
        _hover={{ bgColor: "pcr.100" }}
        mt={"20px"}
        onClick={handleReset}
      >
        {t("column_filter.reset")}
      </Button>
    </Box>
  );
};

export default ColumnFilter;
