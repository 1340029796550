export default {
  type: "Topology",
  arcs: [
    [
      [1198, 39860],
      [128, -134],
      [66, -37],
      [88, -17],
      [93, 2],
      [87, 15],
      [176, 67],
      [80, 46],
      [38, 12],
      [214, -21],
      [92, 30],
      [169, 115],
      [85, 35],
      [60, 7],
      [31, 38],
      [28, 43],
      [48, 23],
      [291, -67],
      [45, -25],
      [42, -37],
      [54, -73],
      [4, -9],
      [35, -12],
      [24, 6],
      [22, 9],
      [28, 3],
      [137, -47],
      [79, 2],
      [51, -15],
      [16, -1],
      [36, 19],
      [7, 12],
      [-3, 20],
      [9, 40],
      [4, 6],
      [59, 2],
      [7, 73],
      [31, 55],
      [42, 31],
      [202, 43],
      [28, 8],
      [20, 15],
      [36, 44],
      [2, 8],
      [28, 57],
      [5, 8],
      [15, 108],
      [0, 43],
      [6, 44],
      [21, 39],
      [28, 21],
      [59, 6],
      [31, 9],
      [125, 101],
      [62, 124],
      [-8, 140],
      [-84, 151],
      [-67, 54],
      [-10, 15],
      [12, 32],
      [29, 37],
      [56, 53],
      [61, 26],
      [124, 26],
      [59, 38],
      [141, 172],
      [81, 38],
      [33, 23],
      [28, 31],
      [17, 35],
      [-1, 39],
      [-19, 13],
      [-11, 16],
      [24, 49],
      [61, 59],
      [77, 39],
      [122, 25],
      [38, 7],
      [82, -13],
      [39, -53],
      [12, -86],
      [1, -115],
      [-10, -110],
      [-46, -198],
      [-10, -98],
      [12, -94],
      [31, -61],
      [52, -40],
      [75, -28],
      [79, -37],
      [81, -77],
      [181, -248],
      [27, -13],
      [56, -18],
      [133, -72],
      [50, -2],
      [20, 10],
      [45, 37],
      [17, 7],
      [15, -5],
      [9, -3],
      [21, -35],
      [22, -14],
      [45, -6],
      [88, 2],
      [43, -14],
      [73, -89],
      [-42, -90],
      [-82, -93],
      [-50, -107],
      [-11, -18],
      [-5, -11],
      [49, -2],
      [44, -15],
      [164, -82],
      [19, -14],
      [18, -24],
      [28, -61],
      [50, -82],
      [24, -50],
      [16, -50],
      [5, -66],
      [-12, -48],
      [-17, -45],
      [-11, -59],
      [113, 32],
      [94, -39],
      [86, -15],
      [91, 105],
      [71, 129],
      [11, 65],
      [-32, 64],
      [-27, 16],
      [-62, 11],
      [-28, 15],
      [-26, 36],
      [-24, 69],
      [-31, 29],
      [-6, 10],
      [-2, 10],
      [2, 10],
      [6, 9],
      [94, 81],
      [-50, 133],
      [12, 43],
      [73, 53],
      [63, 18],
      [27, 15],
      [23, 36],
      [9, 47],
      [-6, 86],
      [11, 49],
      [53, 74],
      [207, 130],
      [2, 3],
      [21, 37],
      [13, 44],
      [16, 37],
      [31, 22],
      [34, 15],
      [33, 28],
      [26, 39],
      [43, 137],
      [83, 162],
      [18, 84],
      [-4, 49],
      [-38, 135],
      [-1, 55],
      [8, 48],
      [-6, 38],
      [-38, 25],
      [-27, 51],
      [22, 94],
      [50, 92],
      [54, 47],
      [139, 16],
      [57, -14],
      [2, -48],
      [73, -64],
      [22, -8],
      [31, 11],
      [14, 23],
      [10, 27],
      [19, 24],
      [46, 32],
      [23, 4],
      [9, -24],
      [17, -366],
      [-4, -58],
      [5, -22],
      [21, -18],
      [63, -33],
      [26, -19],
      [57, -138],
      [36, -46],
      [60, -38],
      [67, -23],
      [327, -3],
      [54, 20],
      [44, 56],
      [1, 30],
      [-5, 43],
      [6, 44],
      [31, 32],
      [31, 0],
      [69, -39],
      [37, -8],
      [59, 18],
      [190, 91],
      [125, 20],
      [56, -4],
      [58, -14],
      [72, -44],
      [19, -62],
      [1, -76],
      [18, -83],
      [42, -53],
      [113, -93],
      [29, -65],
      [2, -86],
      [95, -2],
      [120, 43],
      [80, 46],
      [38, 74],
      [-37, 68],
      [-118, 111],
      [-26, 42],
      [-11, 33],
      [1, 34],
      [15, 52],
      [21, 37],
      [60, 50],
      [21, 26],
      [22, 83],
      [-21, 42],
      [-50, 19],
      [-128, 23],
      [-83, 30],
      [-45, 52],
      [50, 79],
      [-25, 15],
      [-13, 22],
      [-7, 27],
      [-4, 36],
      [13, 40],
      [0, 84],
      [4, 28],
      [25, 34],
      [61, 57],
      [24, 29],
      [35, 124],
      [12, 29],
      [28, 13],
      [270, 72],
      [74, 1],
      [45, -35],
      [6, -43],
      [-16, -78],
      [13, -42],
      [118, -37],
      [22, -18],
      [15, -19],
      [18, -18],
      [32, -14],
      [37, -5],
      [100, 8],
      [204, -49],
      [52, 9],
      [22, 34],
      [18, 27],
      [22, 95],
      [2, 98],
      [-21, 74],
      [-60, 46],
      [-121, 12],
      [-52, 55],
      [-24, 73],
      [14, 37],
      [31, 27],
      [27, 45],
      [6, 54],
      [-2, 44],
      [11, 34],
      [49, 29],
      [34, 48],
      [-7, 53],
      [-20, 55],
      [-6, 57],
      [63, 69],
      [16, 24],
      [11, 35],
      [15, 123],
      [93, -69],
      [118, -12],
      [290, 73],
      [51, 5],
      [44, -11],
      [218, -120],
      [28, -38],
      [-14, -27],
      [-63, -47],
      [-14, -19],
      [18, -33],
      [30, -1],
      [61, 17],
      [33, -10],
      [82, -43],
      [28, -1],
      [54, 15],
      [29, -1],
      [26, -12],
      [49, -38],
      [29, -12],
      [28, 2],
      [96, 30],
      [49, 6],
      [99, -9],
      [49, -15],
      [40, 6],
      [40, 38],
      [64, 101],
      [12, 10],
      [32, 13],
      [12, 13],
      [5, 19],
      [-3, 39],
      [2, 15],
      [54, 118],
      [30, 134],
      [24, 47],
      [184, 168],
      [51, 28],
      [198, 59],
      [200, 31],
      [102, -20],
      [89, -40],
      [88, -4],
      [97, 86],
      [0, 0],
      [22, 30],
      [25, 18],
      [29, 6],
      [50, -11],
      [19, -10],
      [16, -15],
      [15, -18],
      [24, -14],
      [26, -4],
      [27, 5],
      [27, 13],
      [0, 0],
      [1, 0],
      [0, 0],
      [83, 22],
      [68, 6],
      [147, -27],
      [1, 0],
      [4, -1],
      [22, -2],
      [21, 3],
      [54, -5],
      [93, -63],
      [53, -19],
      [33, 7],
      [56, 42],
      [32, 19],
      [130, 19],
      [98, 71],
      [122, 60],
      [126, 40],
      [203, 9],
      [290, 138],
      [48, 9],
      [136, 24],
      [67, -21],
      [-1, -49],
      [-1, -52],
      [-61, -75],
      [-173, -88],
      [-30, -67],
      [19, -38],
      [12, -44],
      [18, -88],
      [19, -42],
      [19, -37],
      [0, -34],
      [-42, -39],
      [30, -11],
      [67, -11],
      [32, -15],
      [33, -30],
      [16, -24],
      [17, -18],
      [39, -14],
      [120, -4],
      [45, -28],
      [26, -84],
      [1, -47],
      [-8, -27],
      [-10, -27],
      [-8, -44],
      [0, -49],
      [6, -26],
      [17, -15],
      [27, -18],
      [60, -21],
      [59, -7],
      [48, -29],
      [25, -83],
      [54, -123],
      [99, -80],
      [116, -44],
      [68, -11],
      [87, -14],
      [88, -55],
      [47, -21],
      [42, -4],
      [166, 20],
      [141, -13],
      [5, -2],
      [23, -9],
      [52, -36],
      [26, -11],
      [27, 1],
      [46, 16],
      [22, 3],
      [25, -8],
      [71, -22],
      [163, -97],
      [668, -45],
      [235, -50],
      [181, -103],
      [60, -22],
      [115, -5],
      [292, 57],
      [124, -20],
      [175, 12],
      [19, -7],
      [41, -43],
      [22, -14],
      [121, -4],
      [346, -104],
      [43, -4],
      [44, 8],
      [1, -30],
      [-6, -27],
      [-10, -23],
      [-16, -21],
      [-4, -97],
      [-8, -15],
      [-24, -45],
      [-53, -27],
      [-71, 2],
      [-96, -11],
      [-70, -61],
      [-61, -79],
      [-70, -68],
      [-132, -65],
      [-69, -47],
      [-35, -16],
      [-38, -3],
      [-31, -35],
      [-38, -81],
      [-41, -64],
      [-12, -20],
      [-74, -43],
      [22, -38],
      [16, -46],
      [17, -96],
      [0, 0],
      [1, 0],
      [37, -48],
      [10, -53],
      [25, -34],
      [78, 12],
      [-42, 67],
      [44, -14],
      [86, -6],
      [40, -21],
      [79, -15],
      [54, -35],
      [86, -79],
      [73, -10],
      [84, 8],
      [38, -7],
      [29, -5],
      [10, -34],
      [12, -37],
      [-82, -140],
      [-268, -190],
      [-50, -36],
      [-65, -81],
      [23, -22],
      [7, -25],
      [-8, -26],
      [-22, -28],
      [-15, -9],
      [-44, -36],
      [38, -41],
      [13, -10],
      [2, -3],
      [3, -4],
      [2, -4],
      [1, -4],
      [77, -83],
      [86, 6],
      [92, 41],
      [83, 18],
      [15, 3],
      [48, -72],
      [-21, -78],
      [-20, -74],
      [-26, -55],
      [-86, -182],
      [-9, -131],
      [23, -43],
      [21, -37],
      [79, -41],
      [99, -11],
      [47, -14],
      [113, -82],
      [28, -20],
      [198, -84],
      [227, -359],
      [31, -32],
      [43, -22],
      [25, -2],
      [2, -4],
      [3, -7],
      [-22, -50],
      [-55, -63],
      [-57, -67],
      [-135, -13],
      [-113, 57],
      [-141, -12],
      [20, 29],
      [33, 47],
      [77, 3],
      [85, -10],
      [61, 12],
      [-36, 10],
      [-26, 28],
      [-27, 43],
      [-108, 52],
      [52, 22],
      [-11, 67],
      [-42, 73],
      [-36, 47],
      [-226, 222],
      [-52, 41],
      [-157, 65],
      [-65, 10],
      [-48, -39],
      [-29, -84],
      [11, -75],
      [76, -13],
      [-104, -47],
      [-14, -15],
      [-16, -25],
      [-79, -14],
      [-31, -15],
      [-51, -49],
      [-67, -49],
      [-75, -22],
      [-72, 30],
      [41, 10],
      [52, 21],
      [45, 28],
      [19, 31],
      [-26, 66],
      [-117, 23],
      [-35, 56],
      [-50, -17],
      [-277, 60],
      [-26, 11],
      [-68, 66],
      [-18, 10],
      [-30, -4],
      [-102, -34],
      [-33, -54],
      [-23, -12],
      [-28, 13],
      [-25, 53],
      [-34, 12],
      [-24, -16],
      [-8, -36],
      [11, -36],
      [31, -16],
      [0, -29],
      [-63, -3],
      [-58, -33],
      [-31, -53],
      [16, -65],
      [46, -29],
      [58, 8],
      [109, 48],
      [-34, -55],
      [-100, -78],
      [-21, -64],
      [0, 0],
      [-30, -40],
      [-70, -20],
      [-225, -7],
      [-108, -24],
      [-466, -286],
      [-483, -219],
      [-283, -99],
      [-48, -38],
      [-23, -14],
      [-104, -24],
      [-79, -47],
      [-38, -7],
      [22, 57],
      [32, 49],
      [19, 42],
      [-14, 34],
      [27, -4],
      [19, 5],
      [11, 18],
      [1, 36],
      [19, 0],
      [14, -33],
      [12, -16],
      [14, 2],
      [19, 18],
      [-39, 52],
      [32, -3],
      [31, 2],
      [29, 10],
      [6, -9],
      [1, 6],
      [10, -12],
      [9, -14],
      [1, -3],
      [-10, -2],
      [-29, 2],
      [0, -29],
      [63, 8],
      [15, 47],
      [3, 53],
      [26, 25],
      [50, 6],
      [41, 17],
      [32, 32],
      [23, 49],
      [-34, -13],
      [-73, -49],
      [-20, -3],
      [-54, 54],
      [-22, 35],
      [-11, 54],
      [-61, -31],
      [-77, -64],
      [-47, -69],
      [27, -46],
      [0, -24],
      [-47, 2],
      [-49, 42],
      [-21, -20],
      [-20, 0],
      [-19, 80],
      [-36, -62],
      [18, -34],
      [96, -37],
      [0, -23],
      [-36, -19],
      [-52, -7],
      [-36, 12],
      [7, 37],
      [-93, 2],
      [-117, -61],
      [-105, -97],
      [-58, -104],
      [0, -24],
      [18, 0],
      [-25, -35],
      [-11, -36],
      [7, -34],
      [29, -28],
      [-28, -37],
      [-5, -28],
      [2, -27],
      [-8, -38],
      [-21, -13],
      [-27, -1],
      [-13, -13],
      [24, -52],
      [-32, -51],
      [-13, -42],
      [-22, -27],
      [-61, -10],
      [-37, 16],
      [-33, 21],
      [-24, -10],
      [-15, -81],
      [30, -35],
      [26, -80],
      [34, -16],
      [31, 19],
      [22, 44],
      [7, 53],
      [-12, 43],
      [56, -22],
      [12, -40],
      [-2, -56],
      [12, -67],
      [-31, -12],
      [-8, -19],
      [11, -24],
      [28, -23],
      [-10, -55],
      [26, -46],
      [48, -28],
      [55, -1],
      [-9, 14],
      [-2, 1],
      [-11, 8],
      [42, 31],
      [64, 60],
      [32, 16],
      [-26, -59],
      [17, -56],
      [32, -54],
      [17, -54],
      [2, -128],
      [19, -144],
      [-29, 9],
      [-32, 17],
      [4, 25],
      [-4, 37],
      [0, 16],
      [-28, -84],
      [-2, -68],
      [29, -38],
      [62, 5],
      [-9, 14],
      [-5, 12],
      [-7, 26],
      [56, -46],
      [35, -56],
      [29, -56],
      [37, -51],
      [0, -25],
      [-22, -4],
      [-12, -7],
      [-10, -9],
      [-16, -9],
      [39, -52],
      [42, 59],
      [9, 47],
      [-17, 46],
      [-34, 56],
      [42, -35],
      [51, -25],
      [35, -27],
      [-9, -40],
      [45, -47],
      [23, -17],
      [30, -17],
      [57, -73],
      [61, -59],
      [38, 27],
      [-10, 1],
      [-19, -2],
      [-9, 1],
      [0, 29],
      [47, 1],
      [41, -15],
      [31, -28],
      [18, -39],
      [-3, -35],
      [-28, -41],
      [11, -26],
      [-23, -36],
      [-10, -21],
      [-8, -23],
      [-17, 0],
      [-22, -3],
      [-12, -42],
      [-5, -124],
      [-16, -60],
      [-38, -63],
      [-84, -97],
      [-5, 17],
      [-12, 38],
      [-19, 43],
      [-23, 32],
      [43, 88],
      [11, 48],
      [-13, 42],
      [-33, 15],
      [-32, -20],
      [-25, -32],
      [-10, -26],
      [-32, -62],
      [-2, -37],
      [71, -29],
      [-1, -29],
      [-22, -28],
      [-33, -10],
      [0, -26],
      [20, -5],
      [36, -15],
      [22, -4],
      [-23, -66],
      [-52, -36],
      [-63, -6],
      [-52, 46],
      [-31, 42],
      [-73, 70],
      [-52, 36],
      [-30, 1],
      [-74, -63],
      [0, -24],
      [39, 0],
      [-76, -264],
      [-20, -152],
      [17, -165],
      [38, -123],
      [26, -60],
      [24, -26],
      [7, -42],
      [-16, -270],
      [10, -169],
      [87, -384],
      [12, -99],
      [8, -29],
      [12, -22],
      [40, -52],
      [8, -18],
      [6, -81],
      [17, -77],
      [53, -143],
      [61, -107],
      [77, -104],
      [117, -111],
      [59, -104],
      [92, -94],
      [104, -81],
      [77, -30],
      [163, -212],
      [85, -83],
      [87, -68],
      [54, -29],
      [124, -40],
      [6, -2],
      [137, -19],
      [55, -32],
      [111, -89],
      [191, -74],
      [28, -43],
      [25, -27],
      [874, -742],
      [333, -202],
      [57, -22],
      [28, -34],
      [134, -46],
      [76, -45],
      [28, 17],
      [22, 28],
      [16, 15],
      [8, 3],
      [6, 8],
      [9, 9],
      [17, 3],
      [17, -7],
      [8, -18],
      [5, -17],
      [10, -8],
      [54, -28],
      [42, -63],
      [32, -63],
      [28, -28],
      [54, -13],
      [51, -34],
      [25, -51],
      [-21, -61],
      [409, -1022],
      [45, -77],
      [12, -27],
      [18, -137],
      [12, -33],
      [34, -53],
      [92, -194],
      [39, -136],
      [48, -322],
      [115, -404],
      [112, -395],
      [102, -259],
      [59, -122],
      [177, -281],
      [16, -58],
      [19, -41],
      [415, -492],
      [182, -160],
      [205, -135],
      [89, -76],
      [119, -196],
      [375, -367],
      [102, -65],
      [164, -67],
      [115, -22],
      [45, -37],
      [22, -59],
      [17, -158],
      [19, -75],
      [32, -55],
      [48, -22],
      [16, -4],
      [48, -10],
      [56, -34],
      [102, -85],
      [102, -47],
      [231, -41],
      [119, -40],
      [39, -35],
      [78, -103],
      [30, -21],
      [52, -18],
      [117, -86],
      [55, -29],
      [57, -11],
      [63, -13],
      [332, 1],
      [340, -78],
      [129, -3],
      [473, 81],
      [465, -27],
      [722, 88],
      [239, -61],
      [4, -7],
      [81, -25],
      [58, -104],
      [53, 3],
      [-9, -61],
      [21, -72],
      [25, -142],
      [2, -49],
      [0, -67],
      [-25, -43],
      [-50, -62],
      [-60, -56],
      [-52, -24],
      [-58, -19],
      [-50, -44],
      [-76, -94],
      [-102, -82],
      [-292, -152],
      [-72, -97],
      [-24, -145],
      [14, -154],
      [44, -128],
      [62, -90],
      [79, -76],
      [173, -121],
      [181, -58],
      [43, -35],
      [40, -40],
      [535, -249],
      [454, -249],
      [172, -48],
      [51, -41],
      [45, -45],
      [113, -79],
      [676, -231],
      [60, -51],
      [19, 0],
      [0, 28],
      [18, 0],
      [60, -63],
      [491, -160],
      [417, -233],
      [209, -156],
      [96, -97],
      [108, -163],
      [38, -22],
      [47, -15],
      [103, -81],
      [178, -195],
      [46, -22],
      [58, -15],
      [103, -71],
      [246, -64],
      [31, -20],
      [294, -123],
      [273, -204],
      [223, -43],
      [97, -48],
      [-3, -90],
      [33, 0],
      [73, 24],
      [43, -9],
      [12, -25],
      [1, -34],
      [12, -39],
      [67, -64],
      [11, -24],
      [-16, -172],
      [236, -200],
      [262, -152],
      [56, -13],
      [20, -14],
      [58, -104],
      [33, -36],
      [94, -81],
      [74, -127],
      [42, -57],
      [51, -25],
      [25, -25],
      [191, -270],
      [179, -441],
      [12, -23],
      [15, -18],
      [19, -10],
      [-14, -44],
      [7, -27],
      [11, -24],
      [-4, -37],
      [-23, -38],
      [-46, -38],
      [-24, -113],
      [-37, -45],
      [-104, -79],
      [-65, -167],
      [-10, -40],
      [-11, -80],
      [-26, -84],
      [-7, -36],
      [21, -98],
      [1, -45],
      [-39, -204],
      [8, -31],
      [-15, -12],
      [-30, -54],
      [-15, -12],
      [-62, 5],
      [-23, 8],
      [-23, 13],
      [-138, 113],
      [-47, 17],
      [-118, 0],
      [-38, 7],
      [-70, 48],
      [-71, 33],
      [-89, 88],
      [-104, 73],
      [-38, 14],
      [-27, 20],
      [-183, 284],
      [-34, 35],
      [0, 29],
      [45, 2],
      [26, 26],
      [5, 44],
      [-18, 56],
      [-30, 33],
      [-77, 26],
      [-31, 21],
      [101, 66],
      [23, 82],
      [-39, 93],
      [-85, 98],
      [-76, 65],
      [-42, 51],
      [-19, 55],
      [-1, 73],
      [-6, 65],
      [-17, 51],
      [-33, 34],
      [38, 0],
      [0, 24],
      [-142, 115],
      [-44, 17],
      [-959, 52],
      [-52, 15],
      [-152, 90],
      [-29, 2],
      [-60, -6],
      [-29, 4],
      [-21, 15],
      [-49, 52],
      [-19, 11],
      [-48, 13],
      [-157, 94],
      [-161, 60],
      [-77, 42],
      [-37, 54],
      [67, 31],
      [56, 49],
      [14, 61],
      [-59, 66],
      [57, 22],
      [138, -27],
      [59, 33],
      [20, 63],
      [-55, 4],
      [-121, -41],
      [5, 9],
      [15, 20],
      [-61, 25],
      [-52, -23],
      [-53, -37],
      [-59, -20],
      [-23, 13],
      [-16, 30],
      [-13, 35],
      [-16, 26],
      [-29, 13],
      [-64, 1],
      [-26, 10],
      [-214, 14],
      [-210, -101],
      [-183, -181],
      [-139, -207],
      [-230, -342],
      [-61, -122],
      [-68, -224],
      [-20, -39],
      [-42, -27],
      [-104, -182],
      [-113, -104],
      [-44, -41],
      [-57, -90],
      [-22, -144],
      [7, -48],
      [41, -143],
      [51, -117],
      [-28, -48],
      [-47, -40],
      [-46, -89],
      [-134, -131],
      [-98, -183],
      [-47, -122],
      [-5, -116],
      [35, -108],
      [76, -97],
      [0, -26],
      [-37, -96],
      [86, -110],
      [130, -92],
      [96, -44],
      [357, -20],
      [93, -32],
      [45, -31],
      [51, -47],
      [42, -57],
      [18, -60],
      [26, -30],
      [171, -88],
      [134, -116],
      [51, -17],
      [70, -8],
      [46, -25],
      [34, -41],
      [36, -56],
      [61, -73],
      [89, -73],
      [102, -52],
      [99, -10],
      [-104, -260],
      [-21, -212],
      [-2, -51],
      [10, -51],
      [20, -44],
      [49, -85],
      [11, -44],
      [-16, -122],
      [-51, -221],
      [6, -115],
      [67, -140],
      [11, -32],
      [19, -20],
      [97, -36],
      [40, -6],
      [-56, -40],
      [-29, -27],
      [-12, -26],
      [-3, -150],
      [3, -21],
      [-96, -91],
      [-11, -15],
      [-21, -15],
      [-25, -35],
      [-42, -78],
      [-26, 76],
      [-70, 14],
      [-81, -25],
      [-58, -39],
      [-73, 90],
      [-43, 29],
      [-69, 11],
      [-63, -5],
      [-276, -78],
      [-316, -151],
      [-115, -87],
      [-23, -32],
      [-25, -27],
      [-172, -92],
      [-71, -71],
      [-32, -49],
      [-31, -108],
      [-36, -59],
      [-35, -69],
      [-11, -89],
      [34, -26],
      [6, -12],
      [-1, -68],
      [29, -291],
      [52, -258],
      [1, -98],
      [-22, -280],
      [-24, -50],
      [-112, -112],
      [-40, -59],
      [-30, -9],
      [-87, -79],
      [-91, -33],
      [-272, -149],
      [-96, -91],
      [-391, -511],
      [-50, -124],
      [-76, -405],
      [-42, -119],
      [-27, -50],
      [-32, -43],
      [-37, -36],
      [-40, -27],
      [-135, -31],
      [-42, -22],
      [-168, 54],
      [-516, -30],
      [-77, 22],
      [-76, 38],
      [-68, 48],
      [-53, 51],
      [-58, 82],
      [-30, 79],
      [10, 72],
      [57, 56],
      [-73, 121],
      [-25, 68],
      [10, 59],
      [40, 71],
      [18, 65],
      [-4, 73],
      [-24, 91],
      [-15, 131],
      [51, 79],
      [96, 46],
      [292, 96],
      [26, 28],
      [9, 20],
      [40, 38],
      [10, 21],
      [39, 171],
      [214, 470],
      [37, 164],
      [-5, 128],
      [-46, 106],
      [-83, 99],
      [-21, 16],
      [-39, 19],
      [-17, 18],
      [-24, 39],
      [-5, 21],
      [4, 21],
      [4, 37],
      [23, 44],
      [53, 30],
      [119, 43],
      [181, 126],
      [98, 49],
      [132, 9],
      [206, -26],
      [43, 11],
      [48, 27],
      [76, 67],
      [62, 107],
      [40, 144],
      [19, 162],
      [-5, 163],
      [-15, 76],
      [-29, 57],
      [-42, 36],
      [-60, 13],
      [-41, 31],
      [-42, 72],
      [-162, 388],
      [-117, 898],
      [-23, 133],
      [-110, 357],
      [-107, 148],
      [-71, 138],
      [-81, 85],
      [-82, 59],
      [-25, 48],
      [-22, 95],
      [-27, 99],
      [-39, 145],
      [-60, 100],
      [-26, 64],
      [-51, 386],
      [0, -24],
      [-13, 75],
      [-15, 39],
      [-21, 16],
      [-5, 22],
      [35, 147],
      [-22, 86],
      [-93, 147],
      [-22, 54],
      [-17, 78],
      [-43, 64],
      [-97, 106],
      [-78, 133],
      [-61, 54],
      [-16, 27],
      [-1, 50],
      [-41, -18],
      [-215, 18],
      [-62, -39],
      [-51, -80],
      [-101, -66],
      [-87, -113],
      [-55, -23],
      [-141, 19],
      [-50, 20],
      [-83, 113],
      [-33, 10],
      [-27, -15],
      [-29, -9],
      [-31, -5],
      [-31, 3],
      [0, 26],
      [51, 44],
      [-45, 99],
      [-85, 100],
      [-69, 46],
      [-56, 28],
      [-179, 192],
      [-41, 20],
      [-49, -6],
      [-86, -25],
      [-53, 9],
      [-35, 24],
      [-35, 78],
      [-56, 56],
      [-78, 34],
      [-27, 20],
      [-26, 27],
      [-54, -14],
      [-46, 39],
      [-32, 13],
      [-12, 65],
      [72, 103],
      [20, 82],
      [-19, 66],
      [0, 36],
      [28, 16],
      [34, 8],
      [29, 20],
      [25, 26],
      [62, 25],
      [17, 39],
      [-7, 110],
      [-64, 129],
      [-248, 541],
      [-78, 131],
      [-75, 97],
      [-73, 78],
      [-79, 64],
      [-86, 26],
      [-92, -38],
      [-84, -67],
      [-35, -10],
      [-150, 10],
      [-35, -13],
      [-120, -107],
      [-78, -16],
      [-79, 24],
      [-85, 49],
      [-44, -31],
      [-141, -34],
      [-58, -52],
      [-131, -70],
      [-38, -50],
      [-36, 53],
      [-5, 80],
      [27, 72],
      [63, 32],
      [57, 4],
      [22, 12],
      [31, 27],
      [19, 46],
      [113, 115],
      [91, 34],
      [10, 63],
      [-16, 69],
      [-38, 56],
      [-52, 41],
      [-61, -15],
      [-45, 22],
      [-32, 18],
      [-33, 41],
      [-46, 30],
      [-51, 64],
      [-104, 119],
      [-52, 41],
      [-53, 5],
      [-78, -9],
      [-70, -24],
      [-32, -41],
      [-26, -74],
      [-61, -1],
      [-47, 56],
      [-87, 11],
      [-68, 42],
      [-44, 16],
      [-39, -9],
      [-18, -35],
      [4, -45],
      [11, -51],
      [3, -52],
      [-20, 11],
      [-79, 12],
      [-19, 27],
      [32, 157],
      [-22, 139],
      [-66, 182],
      [-160, 285],
      [-101, 68],
      [-35, 36],
      [-9, 37],
      [-5, 81],
      [-6, 39],
      [-76, 153],
      [-93, 163],
      [-175, 223],
      [-196, 178],
      [-38, 15],
      [-85, -22],
      [-44, 0],
      [-19, 34],
      [-178, -24],
      [-88, -58],
      [34, -114],
      [-90, -6],
      [-27, 6],
      [-26, 28],
      [-17, 37],
      [-14, 17],
      [-21, -30],
      [-21, 0],
      [-56, 66],
      [-445, 213],
      [-110, 3],
      [-232, -45],
      [-51, -22],
      [-102, -68],
      [-105, -36],
      [-68, -93],
      [-64, -18],
      [-67, 22],
      [-23, 49],
      [-9, 63],
      [-28, 64],
      [-34, 49],
      [-55, 111],
      [-37, 59],
      [-159, 187],
      [-87, 74],
      [-90, 54],
      [-58, 19],
      [-225, 11],
      [-41, 9],
      [-200, 131],
      [-70, 29],
      [-33, -28],
      [-21, -41],
      [-45, 45],
      [-211, 324],
      [-279, 331],
      [-300, 273],
      [-45, 35],
      [-76, 23],
      [-44, 37],
      [-24, 9],
      [-85, 16],
      [-23, 10],
      [-44, 55],
      [-24, 67],
      [-9, 74],
      [-2, 78],
      [-14, 74],
      [-34, 76],
      [-69, 123],
      [-65, 92],
      [-279, 216],
      [-31, 19],
      [-26, 30],
      [-11, 49],
      [-16, 28],
      [-81, 12],
      [-28, 13],
      [-38, 45],
      [-47, 41],
      [-51, 36],
      [-51, 24],
      [-220, -5],
      [-35, -9],
      [-16, 48],
      [-36, 75],
      [-45, 69],
      [-39, 31],
      [-23, 52],
      [-62, 83],
      [-13, 33],
      [-8, 60],
      [-19, 63],
      [-154, 318],
      [-69, 104],
      [-340, 266],
      [-142, 40],
      [-196, 104],
      [-70, 24],
      [0, 29],
      [-27, 15],
      [-29, 9],
      [-31, 4],
      [-32, -2],
      [21, -26],
      [-83, 0],
      [-211, 78],
      [-38, 5],
      [-52, 0],
      [-46, -13],
      [-20, -30],
      [-10, -59],
      [-42, -62],
      [-7, -50],
      [-26, 12],
      [-28, 0],
      [-21, -6],
      [-5, -6],
      [-23, 20],
      [-38, 49],
      [-79, 27],
      [-35, 44],
      [-22, 59],
      [0, 43],
      [35, 51],
      [40, 13],
      [82, -29],
      [64, 4],
      [44, 64],
      [25, 91],
      [2, 93],
      [-6, 62],
      [-60, 97],
      [-21, 68],
      [-28, -4],
      [-33, -16],
      [-24, -3],
      [-37, 53],
      [-25, 72],
      [-28, 61],
      [-47, 23],
      [-9, 74],
      [-69, 57],
      [-137, 79],
      [-15, 46],
      [-13, 62],
      [-17, 54],
      [-25, 23],
      [-25, 15],
      [-82, 87],
      [-151, 80],
      [-456, 155],
      [69, 56],
      [27, 62],
      [5, 72],
      [-2, 87],
      [8, 27],
      [17, 16],
      [9, 25],
      [-14, 51],
      [-26, 39],
      [-37, 26],
      [-198, 91],
      [-140, 36],
      [-140, 5],
      [-127, -41],
      [13, -39],
      [9, -15],
      [-80, -37],
      [-55, 59],
      [-62, 189],
      [71, 59],
      [42, 70],
      [62, 212],
      [-9, 46],
      [0, 81],
      [10, 82],
      [21, 50],
      [-24, 65],
      [-11, 81],
      [-4, 156],
      [-14, 78],
      [-32, 77],
      [-228, 465],
      [-182, 247],
      [-41, 46],
      [-93, 44],
      [-26, 60],
      [-64, 290],
      [-88, 920],
      [-36, 142],
      [-118, 282],
      [-84, 133],
      [-127, 90],
      [-87, 145],
      [-44, 33],
      [-53, 16],
      [-105, 70],
      [-58, 16],
      [-47, 23],
      [-28, 6],
      [-13, -16],
      [-10, -21],
      [-23, -16],
      [-28, -4],
      [-26, 15],
      [-45, 45],
      [-96, 74],
      [-47, 50],
      [-31, -19],
      [-92, 79],
      [-52, -5],
      [19, -28],
      [-13, -7],
      [-13, -11],
      [-13, -9],
      [14, -30],
      [18, -24],
      [21, -15],
      [27, -9],
      [0, -23],
      [-17, -12],
      [-8, -11],
      [-16, -32],
      [38, -22],
      [-11, -20],
      [-30, 5],
      [-16, 50],
      [-19, 30],
      [-137, 62],
      [-146, 130],
      [-32, 16],
      [-77, 72],
      [-29, 16],
      [-84, 6],
      [-34, 9],
      [-29, 14],
      [-100, 112],
      [-17, 5],
      [-20, 48],
      [-47, 33],
      [-130, 57],
      [-35, 25],
      [-26, 35],
      [-15, 50],
      [-34, -16],
      [-54, -8],
      [-48, 7],
      [-22, 30],
      [-20, 36],
      [-136, 57],
      [-20, 87],
      [-300, 160],
      [-91, 66],
      [-28, -37],
      [-21, -40],
      [-11, -48],
      [3, -60],
      [-131, 50],
      [-52, 39],
      [5, 44],
      [-53, 89],
      [-80, 41],
      [-288, 50],
      [-138, 48],
      [-83, 45],
      [-26, -10],
      [-132, 3],
      [-66, 14],
      [-22, 15],
      [-30, 43],
      [-185, 14],
      [-72, -13],
      [-35, -18],
      [-83, -66],
      [-30, -33],
      [-19, -13],
      [-22, 0],
      [-23, 3],
      [-22, -3],
      [-176, -122],
      [-48, -9],
      [-34, -13],
      [-70, -65],
      [-212, -137],
      [-62, -77],
      [15, -75],
      [-29, -43],
      [-9, -21],
      [-3, -27],
      [-8, -10],
      [-44, -28],
      [-15, -14],
      [-15, -45],
      [5, -42],
      [-1, -41],
      [-39, -42],
      [-71, -34],
      [-160, -50],
      [-82, -46],
      [-44, -37],
      [-37, -39],
      [-67, -95],
      [-9, -28],
      [4, -61],
      [-4, -30],
      [-17, -58],
      [-22, -46],
      [-135, -147],
      [-46, -90],
      [43, -78],
      [0, -24],
      [-66, -15],
      [-84, -95],
      [-75, -30],
      [-22, -18],
      [-17, -21],
      [-7, -19],
      [-15, -30],
      [-35, -18],
      [-69, -17],
      [-205, -133],
      [-496, -118],
      [-141, -90],
      [-119, -27],
      [-351, 0],
      [-80, -26],
      [-37, 0],
      [-23, 18],
      [-29, 11],
      [-30, 1],
      [-56, 183],
      [-14, 97],
      [44, 79],
      [124, 132],
      [58, 89],
      [147, 120],
      [64, 69],
      [22, 44],
      [24, 85],
      [18, 47],
      [31, 34],
      [42, 30],
      [32, 41],
      [5, 67],
      [-11, 23],
      [-34, 70],
      [-63, 60],
      [-35, 72],
      [40, 124],
      [-89, 15],
      [-117, -74],
      [-82, -6],
      [-497, -138],
      [-115, 3],
      [-28, 6],
      [-27, 25],
      [-15, 32],
      [-20, 28],
      [-37, 14],
      [-75, 12],
      [-53, 22],
      [-304, 180],
      [-115, 40],
      [-168, 86],
      [-36, 10],
      [-38, -3],
      [-34, -11],
      [-34, -5],
      [-38, 15],
      [-27, 29],
      [-13, 33],
      [-10, 36],
      [-18, 38],
      [-26, 29],
      [-57, 39],
      [-25, 26],
      [-16, 29],
      [-34, 78],
      [-22, 37],
      [-63, 71],
      [-12, 17],
      [-11, 36],
      [1, 19],
      [7, 23],
      [24, 119],
      [20, 33],
      [96, 51],
      [-75, 59],
      [-86, 88],
      [-65, 107],
      [-8, 119],
      [30, 51],
      [146, 107],
      [98, 162],
      [39, 29],
      [-32, 81],
      [21, 78],
      [52, 62],
      [66, 34],
      [52, 0],
      [103, -29],
      [51, 1],
      [-16, 50],
      [-87, 156],
      [-11, 33],
      [-15, 94],
      [-33, 83],
      [2, 5],
      [15, 60],
      [5, 3],
      [0, 18],
      [2, 10],
      [1, 12],
      [-6, 23],
      [-12, 19],
      [-51, 53],
      [-143, 56],
      [-50, 4],
      [-141, -27],
      [-54, 13],
      [-197, 110],
      [-93, 77],
      [-50, 79],
      [5, 214],
      [-18, 110],
      [-75, 51],
      [-100, 9],
      [-30, 25],
      [-34, 56],
      [-8, 35],
      [3, 30],
      [-4, 27],
      [-47, 38],
      [-50, 73],
      [34, 43],
      [42, 34],
      [10, 7],
      [99, 54],
      [25, 4],
      [52, -1],
      [22, 3],
      [25, 13],
      [51, 38],
      [22, 11],
      [100, -16],
      [105, -54],
      [103, -34],
      [93, 42],
      [5, 24],
      [-16, 17],
      [-14, 21],
      [15, 32],
      [23, 10],
      [67, 1],
      [26, 6],
      [41, 36],
      [50, 82],
      [37, 33],
      [102, 44],
      [101, 26],
      [22, -9],
      [36, -41],
      [15, -5],
      [19, 18],
      [55, 94],
      [77, 62],
      [0, 60],
      [-30, 76],
      [-15, 111],
      [15, 22],
      [92, 105],
      [59, 99],
      [26, 59],
      [3, 47],
      [-22, 16],
      [-123, 50],
      [-38, 28],
      [-23, 43],
      [-19, 47],
      [-19, 28],
      [-9, 14],
      [-125, 88],
      [-130, 69],
      [-22, 55],
      [-15, 158],
      [-12, 29],
      [-31, 45],
      [-6, 30],
      [8, 32],
      [33, 67],
      [7, 22],
      [-18, 57],
      [-35, 23],
      [-93, 22],
      [-38, 27],
      [-65, 61],
      [-38, 13],
      [-74, 14],
      [-77, 54],
      [-58, 82],
      [-32, 158],
      [-8, 68],
      [2, 68],
      [16, 63],
      [36, 56],
      [44, 25],
      [77, 22],
      [25, -9],
      [19, -11],
      [17, -25],
      [17, 5],
      [3, 17],
      [3, 27],
      [2, 19],
      [20, 3],
      [71, -1],
      [61, 17],
      [64, 31],
      [58, 46],
      [39, 61],
      [18, 51],
      [23, 46],
      [30, 35],
      [41, 19],
    ],
    [
      [16644, 32094],
      [89, 12],
      [51, 83],
      [-2, 12],
      [1, 12],
      [5, 10],
      [7, 8],
      [3, 5],
      [20, 38],
      [6, 66],
      [-9, 64],
      [-20, 36],
      [-82, -14],
      [-92, -45],
      [-29, -29],
      [-44, -43],
      [-29, -91],
      [40, -81],
      [85, -43],
    ],
    [
      [16712, 24496],
      [3, 0],
      [-1, 4],
      [-2, 0],
      [-1, -1],
      [0, -2],
      [1, -1],
    ],
    [
      [15416, 5144],
      [109, -112],
      [40, -63],
      [5, -86],
      [-65, -101],
      [-99, 14],
      [-99, 77],
      [-68, 90],
      [-13, 27],
      [-23, 62],
      [-8, 63],
      [35, 29],
      [13, 4],
      [21, 18],
      [15, 4],
      [18, -9],
      [27, -35],
      [12, -9],
      [26, -7],
      [19, -11],
      [16, 4],
      [19, 41],
    ],
    [
      [16198, 9359],
      [39, -4],
      [29, 10],
      [24, 14],
      [25, 6],
      [20, -23],
      [45, -28],
      [51, -23],
      [40, -7],
      [-32, -43],
      [-44, -3],
      [-46, 20],
      [-34, 26],
      [-41, -23],
      [-34, 9],
      [-26, 29],
      [-16, 40],
    ],
    [
      [25497, 10746],
      [88, -10],
      [162, -76],
      [102, -19],
      [-10, -33],
      [-17, -17],
      [-22, -5],
      [-29, 3],
      [-116, -81],
      [-40, -52],
      [-19, -48],
      [-9, -55],
      [45, 1],
      [-139, -242],
      [-76, -198],
      [-308, -415],
      [-257, -427],
      [-55, -120],
      [-49, -81],
      [-72, -62],
      [-80, -130],
      [-19, -53],
      [5, -41],
      [30, -72],
      [4, -43],
      [-11, -39],
      [-40, -43],
      [-9, -37],
      [-7, -79],
      [-34, -112],
      [-18, -82],
      [-42, -140],
      [-137, -173],
      [-55, -106],
      [-14, -65],
      [-6, -69],
      [7, -378],
      [13, -64],
      [20, -53],
      [20, -31],
      [199, -118],
      [106, -22],
      [55, -39],
      [44, -65],
      [27, -92],
      [-47, 47],
      [-27, -16],
      [-19, -44],
      [-25, -39],
      [5, 66],
      [-17, 28],
      [-30, 0],
      [-36, -18],
      [18, -47],
      [-45, -77],
      [32, -119],
      [63, -81],
      [48, 37],
      [40, -24],
      [0, -26],
      [-52, -8],
      [7, -56],
      [43, -64],
      [51, -31],
      [69, -7],
      [41, -27],
      [14, -56],
      [-15, -92],
      [-42, 25],
      [-17, -31],
      [5, -48],
      [23, -24],
      [58, 7],
      [25, -14],
      [24, -48],
      [9, -61],
      [-22, -12],
      [-30, 15],
      [-15, 20],
      [-16, -6],
      [-101, -60],
      [20, -26],
      [-26, -16],
      [-6, -9],
      [12, -25],
      [0, -29],
      [-67, -3],
      [-51, -28],
      [-43, -32],
      [-45, -15],
      [-61, -32],
      [-46, -76],
      [-145, -398],
      [-3, -44],
      [-36, -34],
      [14, -77],
      [52, -124],
      [51, -227],
      [-2, -47],
      [-57, -59],
      [-58, -19],
      [-59, 16],
      [-52, 47],
      [-41, 62],
      [-22, 24],
      [-35, 21],
      [-39, 13],
      [-27, -2],
      [-12, -24],
      [-32, -27],
      [-72, 34],
      [-110, 84],
      [-104, 41],
      [-101, -16],
      [-189, -103],
      [-13, 32],
      [-8, 9],
      [-36, -15],
      [-47, 50],
      [-171, 26],
      [-37, 67],
      [-36, 39],
      [-168, 70],
      [-51, 35],
      [-47, -12],
      [-224, 58],
      [-33, 44],
      [-232, 535],
      [-71, 107],
      [-260, 271],
      [-201, 142],
      [-234, 119],
      [-108, 21],
      [-321, -29],
      [-26, -7],
      [-53, -36],
      [-29, -11],
      [-126, 18],
      [-176, 148],
      [-109, 47],
      [-123, 28],
      [-102, 47],
      [-93, 68],
      [-241, 258],
      [-85, 64],
      [-99, 33],
      [-131, 24],
      [-103, 40],
      [-89, 59],
      [-141, 121],
      [-121, 77],
      [-53, 56],
      [-26, 18],
      [-37, 14],
      [-32, 17],
      [-70, 145],
      [-17, 16],
      [-63, 39],
      [-32, 46],
      [-23, 22],
      [-33, 10],
      [-15, 12],
      [-28, 54],
      [-16, 12],
      [-350, 53],
      [-42, -23],
      [-29, -6],
      [-28, 17],
      [-18, 35],
      [-18, 77],
      [-12, 32],
      [-74, 84],
      [-90, 43],
      [-230, 30],
      [-232, -9],
      [-237, -43],
      [-110, 9],
      [-66, 65],
      [-118, 213],
      [-49, 54],
      [-41, 22],
      [-114, 2],
      [-49, 27],
      [-49, 66],
      [-42, 77],
      [-27, 64],
      [-13, 57],
      [-4, 50],
      [-8, 46],
      [-24, 44],
      [-33, 45],
      [-27, 53],
      [2, 47],
      [49, 26],
      [4, -16],
      [41, 27],
      [13, 15],
      [-6, 11],
      [21, 106],
      [6, 3],
      [15, 70],
      [3, 33],
      [-6, 18],
      [-25, 43],
      [-8, 34],
      [-3, 26],
      [2, 5],
      [7, 7],
      [67, 145],
      [17, 102],
      [18, 62],
      [4, 54],
      [-32, 40],
      [87, 38],
      [75, 89],
      [76, 65],
      [92, -36],
      [86, 65],
      [37, 46],
      [15, 61],
      [16, 34],
      [39, -2],
      [45, -14],
      [36, -5],
      [60, 58],
      [-4, 93],
      [-12, 96],
      [34, 66],
      [17, -45],
      [33, 4],
      [33, -4],
      [15, -70],
      [71, -174],
      [78, -150],
      [30, -30],
      [81, -44],
      [34, -27],
      [2, -34],
      [90, -55],
      [123, 18],
      [237, 113],
      [97, 81],
      [24, 29],
      [5, 17],
      [-5, 22],
      [-4, 48],
      [-6, 35],
      [-27, 37],
      [-6, 34],
      [99, 93],
      [-16, 33],
      [17, 33],
      [32, 26],
      [33, 10],
      [29, -7],
      [99, -43],
      [64, -13],
      [79, -1],
      [66, 33],
      [25, 85],
      [44, -17],
      [33, 11],
      [32, 19],
      [56, 16],
      [38, 19],
      [23, 4],
      [15, -10],
      [5, -24],
      [2, -26],
      [7, -18],
      [108, -78],
      [17, -26],
      [4, -123],
      [9, -36],
      [42, -107],
      [349, 28],
      [79, -63],
      [-5, -66],
      [4, -35],
      [19, -44],
      [29, -27],
      [84, -38],
      [101, -91],
      [82, -55],
      [133, -28],
      [41, -21],
      [-12, -11],
      [-1, -4],
      [-7, -14],
      [62, 2],
      [183, -28],
      [63, 16],
      [127, 72],
      [117, 30],
      [40, 31],
      [36, 36],
      [39, 26],
      [196, 36],
      [38, 42],
      [120, -73],
      [60, -24],
      [65, -9],
      [42, 4],
      [87, 24],
      [348, -53],
      [166, 11],
      [152, 44],
      [135, 76],
      [93, -46],
      [136, 27],
      [351, 142],
      [94, 68],
      [81, 83],
      [108, 140],
      [31, 14],
      [94, -8],
      [59, 4],
      [216, 59],
      [40, 26],
      [27, 13],
      [28, 7],
      [80, -7],
      [28, -16],
      [52, -70],
      [19, -16],
      [34, -4],
      [73, -25],
      [91, 6],
      [28, -6],
      [12, -13],
      [29, -48],
      [16, -17],
      [54, -12],
      [50, 21],
      [49, 28],
      [157, 59],
      [74, 103],
      [107, 221],
      [-9, 29],
      [-4, 9],
      [2, 9],
      [11, 31],
      [-10, 12],
      [17, 0],
      [9, -40],
      [-16, -92],
      [13, -50],
      [35, -27],
      [47, -10],
      [51, -2],
      [83, 15],
      [225, 83],
      [63, 46],
      [34, 33],
      [112, 56],
      [114, 113],
      [45, 20],
    ],
    [
      [23891, 11243],
      [0, -54],
      [46, -24],
      [32, -36],
      [23, -45],
      [16, -52],
      [-25, -25],
      [-33, -27],
      [-67, 67],
      [-44, 57],
      [-8, 60],
      [39, 79],
      [21, 0],
    ],
    [
      [23724, 11400],
      [-24, 29],
      [3, 65],
      [26, 64],
      [44, 27],
      [94, -1],
      [23, -23],
      [-20, -55],
      [19, -16],
      [24, -29],
      [15, -9],
      [0, -24],
      [-76, 0],
      [15, -45],
      [9, -17],
      [15, -16],
      [0, -28],
      [-50, -10],
      [-41, 29],
      [-37, 39],
      [-39, 20],
    ],
    [
      [23597, 11687],
      [-5, -38],
      [-108, 48],
      [-86, 76],
      [61, 46],
      [104, 5],
      [44, -15],
      [29, -44],
      [-15, -16],
      [-8, -19],
      [-16, -43],
    ],
    [
      [5294, 13690],
      [6, -21],
      [14, -11],
      [20, 2],
      [18, 16],
      [3, 3],
      [5, 6],
      [14, -9],
      [-57, -51],
      [-14, -26],
      [12, -28],
      [-27, -54],
      [-21, -130],
      [-27, -103],
      [-13, -17],
      [-30, -9],
      [-35, 5],
      [-11, 20],
      [-3, 27],
      [-10, 27],
      [-104, 185],
      [-45, 125],
      [12, 81],
      [0, 28],
      [-21, 0],
      [0, 26],
      [41, 78],
      [73, -50],
      [90, 6],
      [77, -15],
      [33, -111],
    ],
    [
      [4865, 14147],
      [1, -124],
      [17, -154],
      [-18, -61],
      [-45, -21],
      [-59, 5],
      [-42, 26],
      [7, 42],
      [0, 24],
      [-70, 76],
      [-23, 49],
      [15, 60],
      [44, 31],
      [129, 29],
      [44, 18],
    ],
    [
      [20973, 19908],
      [-28, 16],
      [-43, -5],
      [-47, -17],
      [-38, -22],
      [-23, 20],
      [-64, 32],
      [-30, 28],
      [20, 75],
      [15, 35],
      [25, 18],
      [-9, 15],
      [-12, 37],
      [58, 0],
      [180, -65],
      [56, -39],
      [-9, -23],
      [-1, -23],
      [10, -30],
      [-20, -13],
      [-40, -39],
    ],
    [
      [4776, 21267],
      [-67, -52],
      [-1, -106],
      [-13, -85],
      [-105, 8],
      [23, 35],
      [12, 36],
      [4, 37],
      [0, 36],
      [16, 31],
      [36, 40],
      [55, 48],
      [43, 19],
      [6, 46],
      [-6, 52],
      [6, 40],
      [19, 4],
      [50, -7],
      [11, 16],
      [6, 18],
      [15, 18],
      [37, 29],
      [-1, -30],
      [6, -22],
      [13, -26],
      [27, -30],
      [8, -45],
      [-10, -46],
      [-25, -36],
      [-165, -28],
    ],
    [
      [9111, 19356],
      [81, -79],
      [17, -26],
      [0, -38],
      [-32, -65],
      [-9, -39],
      [-10, -82],
      [-24, -59],
      [-63, -108],
      [-47, -139],
      [-35, -52],
      [-63, -20],
      [-43, -21],
      [-33, -51],
      [-51, -110],
      [-37, -44],
      [-70, -64],
      [-31, -51],
      [-24, -75],
      [-4, -58],
      [12, -161],
      [6, -36],
      [67, -197],
      [30, -50],
      [42, -21],
      [23, -21],
      [64, -93],
      [63, -47],
      [-6, -59],
      [-105, -289],
      [-18, -121],
      [27, -104],
      [17, 13],
      [20, 12],
      [17, -1],
      [6, -24],
      [-9, -36],
      [-18, -9],
      [-19, 0],
      [-14, -10],
      [-21, -49],
      [-14, -47],
      [-2, -45],
      [17, -41],
      [-19, 0],
      [0, -24],
      [14, -8],
      [11, -13],
      [14, -8],
      [0, -23],
      [-35, -46],
      [-25, -80],
      [-15, -96],
      [-5, -94],
      [4, -48],
      [14, -94],
      [4, -53],
      [-7, -39],
      [-26, -63],
      [-6, -56],
      [-7, -23],
      [-13, -30],
      [-13, -40],
      [-6, -51],
      [19, -170],
      [-8, -106],
      [-70, -248],
      [47, -68],
      [-17, -121],
      [-71, -203],
      [-34, -167],
      [-5, -55],
      [9, -57],
      [23, -37],
      [66, -51],
      [0, -24],
      [-78, -30],
      [-73, -87],
      [-41, -107],
      [16, -93],
      [-21, -34],
      [9, -22],
      [20, -20],
      [12, -26],
      [-6, -30],
      [-27, -76],
      [-6, -39],
      [-25, -36],
      [-52, -22],
      [-48, -35],
      [-12, -78],
      [-22, 14],
      [-11, 13],
      [2, 19],
      [12, 30],
      [-44, 35],
      [-45, 8],
      [-44, -14],
      [-43, -29],
      [-21, 0],
      [-14, 34],
      [-26, 21],
      [-58, 28],
      [-72, 89],
      [-16, 13],
      [-28, 17],
      [-65, 73],
      [-24, 17],
      [-30, 8],
      [-40, 19],
      [-36, 25],
      [-21, 26],
      [-20, -28],
      [-40, 25],
      [-54, 18],
      [-103, 11],
      [-54, -26],
      [-47, -54],
      [-32, -52],
      [-11, -24],
      [-83, 27],
      [-67, 66],
      [-116, 141],
      [-26, -21],
      [-23, 18],
      [-23, 32],
      [-25, 24],
      [-41, 10],
      [-30, -4],
      [-4, -13],
      [37, -17],
      [0, -29],
      [-13, -31],
      [17, -18],
      [23, -13],
      [8, -19],
      [3, 3],
      [32, -9],
      [71, 16],
      [14, -20],
      [0, -31],
      [-3, -16],
      [-115, -127],
      [-35, -53],
      [-23, -73],
      [-6, -85],
      [7, -76],
      [17, -68],
      [21, -62],
      [17, -24],
      [17, -20],
      [3, -27],
      [-44, -80],
      [-32, -134],
      [21, 0],
      [-30, -21],
      [-9, 3],
      [-21, 18],
      [-23, -48],
      [-291, -307],
      [-19, -9],
      [-30, -3],
      [-15, -12],
      [-31, -53],
      [-23, -16],
      [-34, 10],
      [-39, 31],
      [-64, 66],
      [0, -78],
      [-41, 72],
      [-70, 61],
      [-77, 39],
      [-66, 8],
      [-62, -28],
      [-74, -56],
      [-49, -73],
      [10, -78],
      [-41, -26],
      [-17, 26],
      [23, 72],
      [-28, 21],
      [-50, 2],
      [-45, 12],
      [0, 26],
      [43, 65],
      [2, 94],
      [-35, 66],
      [-67, -17],
      [-22, 203],
      [-36, 165],
      [-22, 0],
      [-22, -21],
      [-67, 37],
      [-47, 7],
      [13, 20],
      [26, 59],
      [-39, 28],
      [-62, -40],
      [-1, 15],
      [-13, 24],
      [-13, 64],
      [-23, 46],
      [-31, 21],
      [-34, -26],
      [-12, 40],
      [1, 94],
      [-9, 49],
      [-20, 27],
      [-27, 16],
      [-18, -6],
      [9, -37],
      [-18, 13],
      [-13, 19],
      [-7, 27],
      [-3, 33],
      [-8, 42],
      [-17, 11],
      [-20, 2],
      [-16, 12],
      [-21, 55],
      [-3, 33],
      [15, 28],
      [142, 173],
      [16, 71],
      [-47, 83],
      [4, 59],
      [-100, 79],
      [-23, 73],
      [12, 35],
      [50, 62],
      [16, 31],
      [10, 57],
      [-3, 33],
      [-41, 101],
      [-10, 12],
      [3, 16],
      [21, 45],
      [79, 104],
      [38, 63],
      [47, 126],
      [33, 71],
      [-21, 29],
      [22, 52],
      [-4, 73],
      [-36, 159],
      [-8, 173],
      [-13, 41],
      [0, 26],
      [12, 36],
      [-7, 99],
      [6, 21],
      [39, -7],
      [25, -21],
      [89, -148],
      [49, -54],
      [61, -23],
      [79, 19],
      [-147, 48],
      [-29, 30],
      [7, 65],
      [37, 64],
      [45, 59],
      [28, 46],
      [-19, 0],
      [-10, -26],
      [-13, -21],
      [-16, -18],
      [-20, -13],
      [34, 39],
      [31, 64],
      [23, 68],
      [9, 52],
      [9, 122],
      [-4, 43],
      [-24, 56],
      [-62, 77],
      [-84, 52],
      [-89, 7],
      [-79, -58],
      [20, -24],
      [9, 20],
      [13, 4],
      [16, -2],
      [22, 2],
      [-33, -24],
      [-20, -35],
      [-27, -98],
      [-19, 29],
      [25, 43],
      [-18, 32],
      [-37, 27],
      [-42, 41],
      [-8, 2],
      [-7, 7],
      [-11, 28],
      [-2, 30],
      [8, 24],
      [10, 24],
      [4, 26],
      [-22, 141],
      [2, 44],
      [35, 82],
      [1, 47],
      [-36, 54],
      [-41, -24],
      [-8, 14],
      [21, 32],
      [48, 30],
      [9, -1],
      [86, 5],
      [51, 20],
      [21, 4],
      [25, 19],
      [30, 46],
      [23, 59],
      [8, 57],
      [-14, 52],
      [-51, 101],
      [-11, 71],
      [-11, 202],
      [48, 176],
      [-1, 114],
      [-37, 86],
      [-62, 64],
      [-73, 53],
      [-22, -28],
      [-63, 53],
      [-7, 76],
      [31, 199],
      [-11, 71],
      [-30, 101],
      [-41, 89],
      [-45, 39],
      [-52, 32],
      [-22, 77],
      [-13, 95],
      [-21, 85],
      [-59, 66],
      [-69, -5],
      [-146, -89],
      [-58, 28],
      [27, 76],
      [31, 55],
      [-65, 20],
      [-48, -42],
      [-30, -77],
      [-12, -86],
      [-25, 53],
      [-17, 59],
      [-19, 99],
      [0, 23],
      [49, 27],
      [46, 36],
      [33, 57],
      [9, 92],
      [7, -1],
      [13, 19],
      [5, 23],
      [-15, 11],
      [-37, 2],
      [-15, 9],
      [-17, 17],
      [-42, 69],
      [-21, 24],
      [-34, 12],
      [216, 521],
      [17, 61],
      [2, 69],
      [-19, 57],
      [-50, 24],
      [-38, 29],
      [10, 65],
      [31, 71],
      [26, 44],
      [21, 0],
      [9, -46],
      [21, -4],
      [26, 1],
      [20, -30],
      [-2, -31],
      [-11, -46],
      [-7, -50],
      [11, -43],
      [128, -171],
      [93, -56],
      [319, -46],
      [159, -48],
      [66, -7],
      [56, 10],
      [223, 99],
      [57, 51],
      [45, 59],
      [19, 57],
      [195, 92],
      [71, 2],
      [49, -8],
      [38, -20],
      [166, 128],
      [49, 54],
      [102, 178],
      [36, 31],
      [-6, 15],
      [0, 4],
      [-2, 2],
      [-12, 7],
      [92, 35],
      [27, 15],
      [19, 27],
      [31, 67],
      [18, 13],
      [35, 20],
      [49, 49],
      [42, 56],
      [43, 94],
      [55, 26],
      [195, 24],
      [42, 17],
      [79, 79],
      [18, -29],
      [45, 62],
      [17, 58],
      [-2, 158],
      [24, 67],
      [54, 31],
      [64, 18],
      [52, 26],
      [6, -35],
      [16, -18],
      [24, -4],
      [31, 8],
      [30, -50],
      [-2, -133],
      [31, -26],
      [-10, 17],
      [-3, 21],
      [3, 28],
      [10, 36],
      [20, 0],
      [11, -50],
      [-2, -11],
      [-9, -17],
      [0, -24],
      [41, 5],
      [16, 6],
      [21, 13],
      [11, -19],
      [47, -34],
      [7, 92],
      [48, -16],
      [103, -102],
      [0, 26],
      [24, -14],
      [21, -8],
      [24, -4],
      [28, 0],
      [0, -24],
      [-15, -11],
      [-8, -13],
      [-6, -14],
      [-10, -16],
      [33, -15],
      [19, -27],
      [17, -32],
      [29, -30],
      [-17, -29],
      [0, -38],
      [17, -90],
      [32, 77],
      [18, 32],
      [28, 22],
      [-10, 17],
      [-4, 14],
      [-8, 13],
      [-17, 10],
      [36, 22],
      [35, -2],
      [37, -12],
      [39, -8],
      [17, 12],
      [13, 20],
      [16, 4],
      [23, -36],
      [-21, -28],
      [27, -19],
      [19, -20],
      [15, -7],
      [19, 20],
      [19, 0],
      [5, -17],
      [2, -3],
      [3, 1],
      [10, -7],
      [-4, -47],
      [-8, -38],
      [-17, -36],
      [-30, -38],
      [-6, 18],
      [-8, 19],
      [-5, 17],
      [-51, -230],
      [-27, -59],
      [58, 0],
      [-21, 54],
      [52, 25],
      [32, -9],
      [10, -36],
      [-14, -60],
      [14, 0],
      [3, 6],
      [-1, 10],
      [3, 10],
      [18, -20],
      [8, -6],
      [13, 0],
      [43, 43],
      [79, -4],
      [81, -31],
      [53, -34],
      [-33, -44],
      [-43, 5],
      [-47, 24],
      [-44, 15],
      [-48, -26],
      [-14, -61],
      [-10, -68],
      [-36, -51],
      [13, -53],
      [-39, -14],
      [-130, 12],
      [-2, -19],
      [4, -5],
      [-24, -4],
      [22, -45],
      [23, -1],
      [26, 17],
      [29, 5],
      [29, -13],
      [51, -34],
      [17, -8],
      [56, 14],
      [43, 30],
      [42, 23],
      [56, -12],
      [-14, -35],
      [-16, -25],
      [-22, -16],
      [-28, -5],
      [0, -23],
      [103, -53],
      [16, -13],
      [6, -47],
      [16, -22],
      [24, 2],
      [32, 26],
      [19, -37],
      [28, -22],
      [33, -12],
      [37, -7],
      [-94, -33],
      [-29, -25],
      [25, -49],
      [0, -24],
      [-43, 12],
      [-37, -18],
      [-11, -35],
      [31, -37],
      [3, 43],
      [17, -49],
      [81, -112],
      [18, -53],
      [11, -70],
      [87, -178],
      [11, -51],
      [13, -237],
      [10, -38],
      [14, -30],
      [19, -12],
      [40, -9],
      [11, -23],
      [3, -33],
      [15, -39],
    ],
    [
      [12264, 26344],
      [50, -43],
      [39, -84],
      [7, -49],
      [29, -38],
      [-47, -45],
      [-41, 25],
      [-106, 110],
      [-29, 46],
      [42, 32],
      [-2, 47],
      [21, 20],
      [37, -21],
    ],
    [
      [10971, 28096],
      [-21, -27],
      [-14, -40],
      [-1, -44],
      [17, -45],
      [-19, -65],
      [-28, -42],
      [-38, -29],
      [-52, -21],
      [55, -23],
      [49, -63],
      [16, -72],
      [-43, -50],
      [-14, 35],
      [-21, 3],
      [-29, -2],
      [-34, 16],
      [-12, 21],
      [-29, 69],
      [-8, 14],
      [-37, 23],
      [-38, 35],
      [-37, 0],
      [-32, -84],
      [-27, 64],
      [-111, -58],
      [-58, 49],
      [-15, -59],
      [-14, -23],
      [-15, -2],
      [-236, 7],
      [-64, 23],
      [-51, 54],
      [-16, 102],
      [29, 66],
      [61, 40],
      [72, 21],
      [61, 6],
      [30, -8],
      [64, -34],
      [34, -13],
      [51, -5],
      [68, 5],
      [-3, 19],
      [-1, 4],
      [-5, -1],
      [-11, 6],
      [38, 27],
      [0, 28],
      [0, 4],
      [49, -6],
      [107, 2],
      [-13, -55],
      [30, -22],
      [42, -1],
      [21, 9],
      [9, 36],
      [23, 21],
      [25, 15],
      [19, 21],
      [31, 97],
      [22, 42],
      [35, 17],
      [28, -10],
      [26, -24],
      [21, -34],
      [14, -39],
    ],
    [
      [9248, 28936],
      [5, -7],
      [6, -10],
      [10, -8],
      [21, -55],
      [-36, -74],
      [-55, -63],
      [-62, -2],
      [-48, 78],
      [64, 145],
      [54, 32],
      [41, -36],
    ],
    [
      [16423, 37874],
      [57, 74],
      [49, 34],
      [53, -11],
      [67, -58],
      [-37, 18],
      [-38, 7],
      [-82, 2],
      [-156, -285],
      [-4, 23],
      [22, 69],
      [69, 127],
    ],
    [
      [17874, 1463],
      [20, 0],
      [20, -22],
      [8, -41],
      [-12, -13],
      [-44, 0],
      [-18, 13],
      [-14, 26],
      [3, 22],
      [19, 11],
      [18, 4],
    ],
    [
      [22120, 11634],
      [-10, 46],
      [41, 15],
      [11, -46],
      [-42, -15],
    ],
    [
      [22776, 11792],
      [10, -40],
      [16, -20],
      [1, -13],
      [-34, -4],
      [-63, 20],
      [-22, 49],
      [-4, 47],
      [11, 4],
      [51, -13],
      [34, -30],
    ],
    [
      [18758, 12329],
      [47, 1],
      [29, -23],
      [4, -12],
      [-59, -58],
      [-33, -8],
      [-33, 14],
      [-2, 29],
      [14, 29],
      [33, 28],
    ],
    [
      [24638, 12684],
      [20, -9],
      [13, -14],
      [-5, -31],
      [-15, -26],
      [-8, -24],
      [-14, -23],
      [-21, -15],
      [-32, 9],
      [-54, 52],
      [0, 14],
      [20, 21],
      [27, 13],
      [15, 17],
      [9, 17],
      [19, 6],
      [26, -7],
    ],
    [
      [19471, 20267],
      [15, 18],
      [22, 5],
      [-14, -41],
      [-35, -19],
      [12, 37],
    ],
    [
      [17194, 78],
      [-2, -5],
      [8, -26],
      [-8, -35],
      [-29, -12],
      [-23, 8],
      [-13, 11],
      [-13, 1],
      [-109, 52],
      [-56, 17],
      [-28, 16],
      [22, 19],
      [62, -2],
      [32, -8],
      [131, -14],
      [24, -8],
      [7, -5],
      [-5, -9],
    ],
    [
      [15624, 9467],
      [16, -30],
      [4, -37],
      [-41, 2],
      [-49, 38],
      [-30, 49],
      [-19, 41],
      [15, 25],
      [51, 6],
      [31, -24],
      [22, -70],
    ],
    [
      [8204, 21950],
      [10, -29],
      [4, -17],
      [-1, -27],
      [-24, -105],
      [10, -20],
      [1, -19],
      [-12, -28],
      [-18, 2],
      [-22, 31],
      [-22, 19],
      [-20, -10],
      [-14, 11],
      [31, 73],
      [-12, 25],
      [8, 34],
      [21, 52],
      [18, 32],
      [23, -5],
      [19, -19],
    ],
    [
      [8079, 21907],
      [9, -16],
      [3, -14],
      [-114, -25],
      [-52, 11],
      [-11, 33],
      [0, 30],
      [13, 15],
      [21, 10],
      [19, 16],
      [13, 27],
      [5, 32],
      [12, 6],
      [16, 2],
      [18, 16],
      [22, -6],
      [16, -26],
      [9, -29],
      [-1, -33],
      [-3, -32],
      [5, -17],
    ],
    [
      [10553, 26185],
      [27, 25],
      [27, -41],
      [-8, -64],
      [-53, 15],
      [7, 65],
    ],
    [
      [9966, 27143],
      [13, -19],
      [5, -11],
      [0, -15],
      [-7, -27],
      [-27, -17],
      [-40, 1],
      [-38, 12],
      [-24, 23],
      [7, 27],
      [29, 12],
      [28, 32],
      [19, 51],
      [14, 3],
      [10, -41],
      [11, -31],
    ],
    [
      [16360, 38078],
      [49, 0],
      [48, -35],
      [-5, -44],
      [-28, -16],
      [-43, -25],
      [-47, 9],
      [-12, 51],
      [38, 60],
    ],
  ],
  transform: {
    scale: [0.00035007191611576336, 0.00026181917053510995],
    translate: [6.602728312000066, 35.48924388200004],
  },
  objects: {
    "custom.geo": {
      type: "GeometryCollection",
      geometries: [
        {
          arcs: [
            [[0], [1], [2]],
            [[3]],
            [[4]],
            [[5]],
            [[6]],
            [[7]],
            [[8]],
            [[9]],
            [[10]],
            [[11]],
            [[12]],
            [[13]],
            [[14]],
            [[15]],
            [[16]],
            [[17]],
            [[18]],
            [[19]],
            [[20]],
            [[21]],
            [[22]],
            [[23]],
            [[24]],
            [[25]],
            [[26]],
            [[27]],
            [[28]],
            [[29]],
            [[30]],
          ],
          type: "MultiPolygon",
          properties: {
            featurecla: "Admin-0 country",
            scalerank: 0,
            labelrank: 2,
            sovereignt: "Italy",
            sov_a3: "ITA",
            adm0_dif: 0,
            level: 2,
            type: "Sovereign country",
            tlc: "1",
            admin: "Italy",
            adm0_a3: "ITA",
            geou_dif: 0,
            geounit: "Italy",
            gu_a3: "ITA",
            su_dif: 0,
            subunit: "Italy",
            su_a3: "ITA",
            brk_diff: 0,
            name: "Italy",
            name_long: "Italy",
            brk_a3: "ITA",
            brk_name: "Italy",
            brk_group: null,
            abbrev: "Italy",
            postal: "I",
            formal_en: "Italian Republic",
            formal_fr: null,
            name_ciawf: "Italy",
            note_adm0: null,
            note_brk: null,
            name_sort: "Italy",
            name_alt: null,
            mapcolor7: 6,
            mapcolor8: 7,
            mapcolor9: 8,
            mapcolor13: 7,
            pop_est: 60297396,
            pop_rank: 16,
            pop_year: 2019,
            gdp_md: 2003576,
            gdp_year: 2019,
            economy: "1. Developed region: G7",
            income_grp: "1. High income: OECD",
            fips_10: "IT",
            iso_a2: "IT",
            iso_a2_eh: "IT",
            iso_a3: "ITA",
            iso_a3_eh: "ITA",
            iso_n3: "380",
            iso_n3_eh: "380",
            un_a3: "380",
            wb_a2: "IT",
            wb_a3: "ITA",
            woe_id: 23424853,
            woe_id_eh: 23424853,
            woe_note: "Exact WOE match as country",
            adm0_iso: "ITA",
            adm0_diff: null,
            adm0_tlc: "ITA",
            adm0_a3_us: "ITA",
            adm0_a3_fr: "ITA",
            adm0_a3_ru: "ITA",
            adm0_a3_es: "ITA",
            adm0_a3_cn: "ITA",
            adm0_a3_tw: "ITA",
            adm0_a3_in: "ITA",
            adm0_a3_np: "ITA",
            adm0_a3_pk: "ITA",
            adm0_a3_de: "ITA",
            adm0_a3_gb: "ITA",
            adm0_a3_br: "ITA",
            adm0_a3_il: "ITA",
            adm0_a3_ps: "ITA",
            adm0_a3_sa: "ITA",
            adm0_a3_eg: "ITA",
            adm0_a3_ma: "ITA",
            adm0_a3_pt: "ITA",
            adm0_a3_ar: "ITA",
            adm0_a3_jp: "ITA",
            adm0_a3_ko: "ITA",
            adm0_a3_vn: "ITA",
            adm0_a3_tr: "ITA",
            adm0_a3_id: "ITA",
            adm0_a3_pl: "ITA",
            adm0_a3_gr: "ITA",
            adm0_a3_it: "ITA",
            adm0_a3_nl: "ITA",
            adm0_a3_se: "ITA",
            adm0_a3_bd: "ITA",
            adm0_a3_ua: "ITA",
            adm0_a3_un: -99,
            adm0_a3_wb: -99,
            continent: "Europe",
            region_un: "Europe",
            subregion: "Southern Europe",
            region_wb: "Europe & Central Asia",
            name_len: 5,
            long_len: 5,
            abbrev_len: 5,
            tiny: -99,
            homepart: 1,
            min_zoom: 0,
            min_label: 2,
            max_label: 7,
            label_x: 11.076907,
            label_y: 44.732482,
            ne_id: 1159320919,
            wikidataid: "Q38",
            name_ar: "إيطاليا",
            name_bn: "ইতালি",
            name_de: "Italien",
            name_en: "Italy",
            name_es: "Italia",
            name_fa: "ایتالیا",
            name_fr: "Italie",
            name_el: "Ιταλία",
            name_he: "איטליה",
            name_hi: "इटली",
            name_hu: "Olaszország",
            name_id: "Italia",
            name_it: "Italia",
            name_ja: "イタリア",
            name_ko: "이탈리아",
            name_nl: "Italië",
            name_pl: "Włochy",
            name_pt: "Itália",
            name_ru: "Италия",
            name_sv: "Italien",
            name_tr: "İtalya",
            name_uk: "Італія",
            name_ur: "اطالیہ",
            name_vi: "Ý",
            name_zh: "意大利",
            name_zht: "義大利",
            fclass_iso: "Admin-0 country",
            tlc_diff: null,
            fclass_tlc: "Admin-0 country",
            fclass_us: null,
            fclass_fr: null,
            fclass_ru: null,
            fclass_es: null,
            fclass_cn: null,
            fclass_tw: null,
            fclass_in: null,
            fclass_np: null,
            fclass_pk: null,
            fclass_de: null,
            fclass_gb: null,
            fclass_br: null,
            fclass_il: null,
            fclass_ps: null,
            fclass_sa: null,
            fclass_eg: null,
            fclass_ma: null,
            fclass_pt: null,
            fclass_ar: null,
            fclass_jp: null,
            fclass_ko: null,
            fclass_vn: null,
            fclass_tr: null,
            fclass_id: null,
            fclass_pl: null,
            fclass_gr: null,
            fclass_it: null,
            fclass_nl: null,
            fclass_se: null,
            fclass_bd: null,
            fclass_ua: null,
            filename: "ITA.geojson",
          },
        },
      ],
    },
  },
};
