import React from "react";
import { Box, Button } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const FormButtons = ({ onClose, isUpdateForm, setReadOnly, isReadOnly }) => {
  const { t } = useTranslation();

  return (
    <Box
      p="4"
      display="flex"
      justifyContent="space-evenly"
      alignItems="center"
      position="sticky"
      top="0"
      zIndex="1"
    >
      {isUpdateForm && isReadOnly && (
        <Button
          colorScheme="yellow"
          mt={4}
          onClick={() => setReadOnly(!isReadOnly)}
        >
          {t("edit")}
        </Button>
      )}
      {(!isReadOnly || !isUpdateForm) && (
        <Button type="submit" colorScheme="yellow" mt={4}>
          {!isReadOnly && !isUpdateForm ? t("add") : t("save")}
        </Button>
      )}
      <Button colorScheme="yellow" mt={4} onClick={onClose}>
        {t("cancel")}
      </Button>
    </Box>
  );
};

export default FormButtons;
