import {
  Flex,
  Table,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Portal,
} from "@chakra-ui/react";
import React, { useEffect, useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
  useFilters,
} from "react-table";
import Card from "components/card/Card";
import EventsTableContent, { formatDate } from "./EventsTableContent";
import Pagination from "components/table/Pagination";
import { useTranslation } from "react-i18next";
import ColumnVisibilityToggle from "./ColumnVisibilityToggle";
import ColumnFilter from "./DefaultColumnFilter";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import FilterListIcon from "@mui/icons-material/FilterList";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const bgColor = "pcr.200";
const hoverColor = "pcr.100";

export default function EventsTable(props) {
  const { columnsData, tableData, searchText, isDashboard, isMapDashboard } =
    props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const { t } = useTranslation();

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 }, // Set initial page size
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    allColumns,
    toggleHideColumn,
    state: { pageIndex, pageSize },
    setGlobalFilter,
    rows,
  } = tableInstance;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  useEffect(() => {
    setGlobalFilter(searchText || undefined);
  }, [searchText, setGlobalFilter]);

  const exportToCSV = (columns, data) => {
    const visibleColumns = columns.filter((column) =>
      allColumns.some((col) => col.isVisible && col.Header === column.Header)
    );

    const convertToCSV = (data, columns) => {
      const header = columns
        .map((col) => t(`events_list.${col.Header}`))
        .join(",");
      const rows = data.map((row) =>
        columns
          .map((col) => {
            const value = row.values[col.accessor];
            if (col.type === "date") {
              return formatDate(value);
            }
            return value;
          })
          .join(",")
      );
      return [header, ...rows].join("\n");
    };

    const csv = convertToCSV(data, visibleColumns);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    // Create a link element and simulate a click to download the file
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    if (isMapDashboard) {
      headerGroups?.forEach((headers) => {
        headers.headers?.forEach((header) => {
          if (
            header.id !== "eventTime" &&
            header.id !== "action" &&
            header.id !== "description"
          )
            toggleHideColumn(header.id, true);
        });
      });
    }
  }, [toggleHideColumn, isMapDashboard, headerGroups]);

  return (
    <Card
      direction="column"
      w={isMapDashboard ? "50% " : "100%"}
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      {!isDashboard && (
        <Flex>
          <Popover>
            <PopoverTrigger>
              <Button bgColor={bgColor} _hover={{ bgColor: hoverColor }}>
                {" "}
                <ViewWeekIcon /> <Text pl={"2px"}>{t("column_button")}</Text>
              </Button>
            </PopoverTrigger>
            <Portal>
              <PopoverContent borderColor={"black"}>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>{t("column_title")}</PopoverHeader>
                <PopoverBody>
                  <ColumnVisibilityToggle
                    allColumns={allColumns}
                    toggleHideColumn={toggleHideColumn}
                  />
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>
          <Popover>
            <PopoverTrigger>
              <Button
                bgColor={bgColor}
                _hover={{ bgColor: hoverColor }}
                ml={"5px"}
              >
                {" "}
                <FilterListIcon />{" "}
                <Text pl={"2px"}>{t("column_filter.button")}</Text>
              </Button>
            </PopoverTrigger>
            <Portal>
              <PopoverContent borderColor={"black"}>
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverHeader>{t("column_filter.title")}</PopoverHeader>
                <PopoverBody>
                  <ColumnFilter
                    columns={allColumns.filter((col) => col.canFilter)}
                    rows={tableData}
                  />
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>

          <Button
            onClick={() => exportToCSV(columns, rows)}
            bgColor={bgColor}
            _hover={{ bgColor: hoverColor }}
            ml={"5px"}
          >
            <FileDownloadIcon /> <Text pl={"2px"}>{t("export")}</Text>
          </Button>
        </Flex>
      )}

      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: "12px", lg: "14px" }}
                    color="gray.400"
                  >
                    {t(`events_list.${column.Header}`)}
                  </Flex>
                </Th>
              ))}
              <Th>
                <Flex
                  justify="space-between"
                  align="center"
                  fontSize={{ sm: "12px", lg: "14px" }}
                  color="gray.400"
                >
                  {t("events_list.handle_event")}
                </Flex>
              </Th>
            </Tr>
          ))}
        </Thead>
        <EventsTableContent
          page={page}
          prepareRow={prepareRow}
          textColor={textColor}
          {...getTableBodyProps()}
        />
      </Table>

      <Pagination
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageOptions={pageOptions}
        pageCount={pageCount}
        gotoPage={gotoPage}
        nextPage={nextPage}
        previousPage={previousPage}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        pageSize={pageSize}
      />
    </Card>
  );
}
