/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Box,
  Collapse,
  Flex,
  Spinner,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import DashboardIcon from "@mui/icons-material/Dashboard";
// Custom components

import React from "react";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { useEffect, useState } from "react";
import DeviceTable from "./components/DeviceTable";
import { columnsData } from "views/devices/variables/columnsData";
import DeviceCardGrid from "./components/DeviceCardGrid";
import { useDevicesStore } from "contexts/stores";
import { useTranslation } from "react-i18next";
import ToggleViewSwitch from "components/switch/ToggleViewSwitch";
import LoadingSpinner from "components/generic/LoadingSpinner";

export default function Devicepage() {
  const [isListTable, setIsListTable] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [dataWithCoordinates, setDataWithCoordinates] = useState([]);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const { devices, isUpdating, fetchDevices } = useDevicesStore();

  const { t } = useTranslation();

  useEffect(() => {
    fetchDevices();
  }, [fetchDevices]);

  useEffect(() => {
    if (devices?.length > 0) {
      const newDataWithCoordinates = devices.map((item) => {
        const latitude = item.latitude ? item.latitude.toFixed(5) : "N/A";
        const longitude = item.longitude ? item.longitude.toFixed(5) : "N/A";
        return {
          ...item,
          coordinates: `${latitude}, ${longitude}`,
        };
      });

      setDataWithCoordinates(newDataWithCoordinates);
    }
  }, [devices, devices?.length]);

  return (!devices || devices.length === 0) && isUpdating ? (
    <LoadingSpinner />
  ) : (
    <Box
      bgColor="white"
      padding="15px"
      shadow={"lg"}
      // pt={{ base: "130px", md: "80px", xl: "80px" }}
    >
      <Flex>
        <SearchBar setSearchText={setSearchText} />
        <ToggleViewSwitch
          isListTable={isListTable}
          setIsListTable={setIsListTable}
        />
      </Flex>
      {dataWithCoordinates.length !== 0 ? (
        isMobile ? (
          <DeviceCardGrid data={dataWithCoordinates} searchText={searchText} />
        ) : (
          <>
            <Collapse in={isListTable}>
              <DeviceTable
                columnsData={columnsData}
                tableData={dataWithCoordinates}
                searchText={searchText}
              />
            </Collapse>

            <Collapse in={!isListTable}>
              {" "}
              <DeviceCardGrid
                data={dataWithCoordinates}
                searchText={searchText}
              />
            </Collapse>
          </>
        )
      ) : (
        <Flex
          justifyContent={"center"}
          alignItems={"center"}
          w={"100%"}
          minH={"100vh"}
        >
          <Flex alignItems={"center"}>
            <Text pl="0.5rem" fontSize="4xl" as={"b"}>
              {t("empty_data")}
            </Text>
          </Flex>
        </Flex>
      )}
    </Box>
  );
}
