import React from 'react';
import { Box, Flex, Text, Stack } from '@chakra-ui/react';

const MobileEventsTable = ({ data }) => {
  return (
    <Flex direction="column" padding={4} width="90%">
      {Array.isArray(data) ? data.map((item, index) => (
        <Box
          key={index}
          borderWidth="1px"
          borderRadius="lg"
          overflow="hidden"
          padding={4}
          marginBottom={4}
          boxShadow="sm"
        >
          <Stack spacing={2}>
            <Flex justifyContent="space-between">
              <Text fontWeight="bold">Data e Ora:</Text>
              <Text>{item.eventTime}</Text>
            </Flex>
            <Flex justifyContent="space-between">
              <Text fontWeight="bold">Dispositivo:</Text>
              <Text>{item.deviceId}</Text>
            </Flex>
            <Flex justifyContent="space-between">
              <Text fontWeight="bold">Posizione:</Text>
              <Text>{item.location}</Text>
            </Flex>
            <Flex justifyContent="space-between">
              <Text fontWeight="bold">Azione:</Text>
              <Text>{item.action}</Text>
            </Flex>
            <Flex justifyContent="space-between">
              <Text fontWeight="bold">Evento:</Text>
              <Text>{item.description}</Text>
            </Flex>
          </Stack>
        </Box>
      )) : <></>}
    </Flex>
  );
};

export default MobileEventsTable;
