export const columnsData = [
  {
    Header: "name",
    accessor: "name",
    type: "text",
  },
  {
    Header: "location",
    accessor: "location",
    type: "text",
  },
  {
    Header: "version",
    accessor: "version",
    type: "text",
  },
  {
    Header: "registration_date",
    accessor: "registrationDate",
    type: "date",
  },
  {
    Header: "tags",
    accessor: "tags",
    type: "textList",
  },
  {
    Header: "alive",
    accessor: "alive",
    type: "status",
  },
];
