import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

export const columnsData = [
  {
    Header: "event_time",
    accessor: "eventTime",
    type: "date",
  },
  {
    Header: "id",
    accessor: "deviceId",
    type: "textIcon",
    icon: <LogoutOutlinedIcon />,
    link: "devices",
  },
  {
    Header: "location",
    accessor: "location",
    type: "text",
  },
  {
    Header: "action",
    accessor: "action",
    type: "text",
  },
  {
    Header: "description",
    accessor: "description",
    type: "text",
  },
  {
    Header: "counter",
    accessor: "counter",
    type: "text",
  },
];
