import React, { useEffect, useState } from "react";
import FiltersTable from "components/filters/FiltersTable";
import { useEventsStore } from "contexts/stores";
import { useMeasuresStore } from "contexts/stores";
import { convertToString } from "utils/StringConverter";
import LoadingSpinner from "components/generic/LoadingSpinner";

const MeasuresTable = () => {
  const { measuresComplete, isUpdating, fetchMeasuresComplete } =
    useMeasuresStore();
  const { fetchEvents } = useEventsStore();
  const [pollingInterval, setPollingInterval] = useState(null);
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    fetchMeasuresComplete();
  }, [fetchMeasuresComplete]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchMeasuresComplete();
      fetchEvents();
    }, 5000);

    setPollingInterval(intervalId);
    return () => {
      clearInterval(intervalId);
    };
  }, [fetchMeasuresComplete, fetchEvents]);

  useEffect(() => {
    if (measuresComplete) {
      setTableData(
        measuresComplete.map((m) => {
          if (m.enumerative) {
            m.measure.value = m.enumerative.kName;
          }
          let stringMeasure = {};
          for (let key in m.measure) {
            stringMeasure[key] = convertToString(m.measure[key]);
          }
          return stringMeasure;
        })
      );
    } else {
      console.warn("measuresComplete o measuresComplete.measure non definito");
    }
  }, [measuresComplete]);

  return isUpdating && (!measuresComplete || measuresComplete.length === 0) ? (
    <LoadingSpinner />
  ) : (
    <FiltersTable tableData={tableData} />
  );
};

export default MeasuresTable;
