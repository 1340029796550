import groups from "./groups.json";
import { useKeycloak } from "contexts/stores";


const host = "http://pcr-test.mexage.net/api";
const v = "v1";

const getAuthToken = () => {
  return localStorage.getItem("jwt_auth");
};

const post = async (endpoint, data) => {
  const { keycloak } = useKeycloak.getState();
  if (keycloak.isTokenExpired()) {
    const currentTime = Math.floor(Date.now() / 1000);
    if (keycloak.refreshTokenParsed.exp < currentTime) keycloak.logout();
    else {
      if (keycloak.refreshToken)
        await keycloak.updateToken().then((refreshed) => {
          if (!refreshed) {
            keycloak.logout();
          } else {
            localStorage.setItem("jwt_auth", keycloak.token);
          }
        });
    }
  }
  const token = getAuthToken();
  const payload = JSON.stringify(data);
  // console.log("Request: " + endpoint + " - Payload: " + payload);
  const response = await fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: payload,
    credentials: "include",
  });
  const contentType = response.headers.get("content-type");
  if (contentType && contentType.indexOf("application/json") !== -1) {
    if (response.status === 401) {
      if (keycloak.isTokenExpired()) keycloak.logout();
      else return { isSuccess: false, data: undefined, errorMsg: "401" };
    }
    var resp = await response.json();
    //console.log("Response: " + JSON.stringify(resp));
    return { isSuccess: response.status === 200, data: resp };
  } else {
    //console.log("Response: empty");
    return { isSuccess: response.status === 200, data: undefined };
  }
};

const put = async (endpoint, data) => {
  const { keycloak } = useKeycloak.getState();
  if (keycloak.isTokenExpired()) {
    const currentTime = Math.floor(Date.now() / 1000);
    if (keycloak.refreshTokenParsed.exp < currentTime) keycloak.logout();
    else {
      if (keycloak.refreshToken)
        await keycloak.updateToken().then((refreshed) => {
          if (!refreshed) {
            keycloak.logout();
          } else {
            localStorage.setItem("jwt_auth", keycloak.token);
          }
        });
    }
  }
  const token = getAuthToken();
  const payload = JSON.stringify(data);
  try {
    const response = await fetch(endpoint, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: payload,
      credentials: "include",
    });

    const contentType = response.headers.get("content-type");
    let resp;

    if (contentType && contentType.indexOf("application/json") !== -1) {
      if (response.status === 401) {
        if (keycloak.isTokenExpired()) keycloak.logout();
        else return { isSuccess: false, data: undefined, errorMsg: "401" };
      }
      resp = await response.json();
      // console.log(`Response: ${JSON.stringify(resp)}`);
      return { isSuccess: response.status === 200, data: resp };
    } else {
      // console.log("Response: empty");
      return { isSuccess: response.status === 200, data: undefined };
    }
  } catch (error) {
    console.error(`Error in PUT request to ${endpoint}:`, error);
    return { isSuccess: false, data: undefined, error: error.message };
  }
};

const get = async (endpoint) => {
  try {
    const { keycloak } = useKeycloak.getState();
    if (keycloak.isTokenExpired()) {
      const currentTime = Math.floor(Date.now() / 1000);
      if (keycloak.refreshTokenParsed.exp < currentTime) keycloak.logout();
      else {
        if (keycloak.refreshToken)
          await keycloak.updateToken().then((refreshed) => {
            if (!refreshed) {
              keycloak.logout();
            } else {
              localStorage.setItem("jwt_auth", keycloak.token);
            }
          });
      }
    }
    const token = getAuthToken();
    const response = await fetch(endpoint, {
      credentials: "include",
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 200) {
      const contentType = response.headers.get("content-type");

      if (contentType && contentType.indexOf("application/json") !== -1) {
        var resp = await response.json();
        //console.log("Request:" + endpoint + " -> JSON Response: " + JSON.stringify(resp));
        return { isSuccess: true, data: resp };
      } else if (contentType && contentType.indexOf("text/plain") !== -1) {
        resp = await response.text();
        //console.log("Request" + endpoint + "-> TEXT Response:" + resp);
        return { isSuccess: true, data: resp };
      } else {
        // console.log("Request:" + endpoint + " -> Response: empty");
        return { isSuccess: true, data: undefined };
      }
    } else if (response.status === 401) {
      if (keycloak.isTokenExpired()) keycloak.logout();
      else return { isSuccess: false, data: undefined, errorMsg: "401" };
    }
  } catch (error) {
    console.error("Request:" + endpoint + " -> Error: " + error);
  }
  return { isSuccess: false, data: undefined };
};
const del = async (endpoint) => await noPayload(endpoint, "DELETE");
const noPayload = async (endpoint, method) => {
  try {
    const response = await fetch(endpoint, {
      credentials: "include",
      method: method,
    });
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
      var resp = await response.json();
      console.log(
        "Request:" + endpoint + " -> Response: " + JSON.stringify(resp)
      );
      return { isSuccess: response.status === 200, data: resp };
    } else {
      console.log("Request:" + endpoint + " -> Response: empty");
      return { isSuccess: response.status === 200, data: undefined };
    }
  } catch (error) {
    console.error("Request:" + endpoint + " -> Error: " + error);
  }
};

const dataStore = {
  groups: [...groups],
};

const API = {
  editDevice: async (device) => put(`${host}/${v}/Device`, device),
  getAllDevices: async () => get(`${host}/${v}/Device`),
  getAllEvents: async () => get(`${host}/${v}/Event`),
  getEventById: async (id) => get(`${host}/${v}/Event/${id}`),
  createDevice: async (device) => post(`${host}/${v}/Device`, device),
  getAllOperators: async () => get(`${host}/${v}/Keycloak/users`),
  getAllPlants: async () => get(`${host}/${v}/Plant`),
  getAllTags: async () => get(`${host}/${v}/Tag`),
  getAllMeasures: async () => get(`${host}/${v}/Measure`),
  getAllMeasuresComplete: async () => get(`${host}/${v}/Measure/complete`),
  sendOperatorNotification: async (notification) => {
    return { isSuccess: true, data: "Notifica inviata!" };
  },
  createOperator: async (user) => post(`${host}/${v}/Keycloak/users`, user),
  updateOperator: async (user) =>
    put(`${host}/${v}/Keycloak/users/${user.username}`, user),
  getAllGroups: async () => get(`${host}/${v}/Keycloak/groups`),
  createGroup: async (group) => post(`${host}/${v}/Keycloak/groups`, group),
  updateGroup: async (group) => {
    dataStore.groups = dataStore.groups.map((gr) =>
      group.name === gr.name ? group : gr
    );
    return { isSuccess: true, data: dataStore.groups };
  },
  getGroupOperators: async (group) =>
    get(`${host}/${v}/Keycloak/groups/${group.name}/users`),
  getGroupPermissions: async () => {
    return;
  },
  getUserPermission: async () => {
    return;
  },
  sendNotification: async (notification) =>
    post(`${host}/${v}/Notification/sendTopic`, notification),
};
export default API;
