import React, { useState } from "react";
import { Box, Heading } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import API from "api/API";
import { useDevicesStore } from "contexts/stores";
import { DevIsAlive } from "./DeviceCardGrid";
import { enqueueSnackbar } from "notistack";
import FormButtons from "components/slider/FormButtons";
import CreateDeviceTag from "./CreateDevice/CreateDeviceTag";
import CreateDevicePlant from "./CreateDevice/CreateDevicePlant";
import FormInput from "views/operators/components/FormInput";

function CreateDevice({ onClose, deviceData }) {
  const { t } = useTranslation();
  const [readOnly, setReadOnly] = useState(deviceData ? true : false);

  const {
    handleSubmit,
    control,
    formState: { errors },
    register,
    getValues,
  } = useForm({
    defaultValues: {
      id: deviceData?.id || 0,
      name: deviceData?.name || "",
      location: deviceData?.location || "",
      latitude: deviceData?.latitude || "",
      longitude: deviceData?.longitude || "",
      version: deviceData?.version || "",
      registrationTime:
        deviceData?.registrationDate || new Date().toISOString(),
      alive:
        deviceData?.alive || deviceData?.alive === 0
          ? deviceData?.alive
          : DevIsAlive.NOMINAL,
      plant: deviceData?.plant || "",
      tags: deviceData?.tags
        ? deviceData.tags.map((tag) => ({
            value: tag.id,
            label: tag.name,
          }))
        : [],
    },
  });

  const fetchDevices = useDevicesStore((state) => state.fetchDevices);

  const onSubmit = (data) => {
    if (deviceData) {
      console.log(deviceData.tags);
      console.log(data.tags);
      const dataFormatted = {
        ...data,
        tags: data.tags?.map((formtag) => ({
          id:
            deviceData.tags.find((tag) => tag.name === formtag.label)?.id ?? 0,
          name: formtag.label,
          device: data.id,
        })),
        latitude: parseFloat(data.latitude),
        longitude: parseFloat(data.longitude),
        registrationTime: new Date(data.registrationTime).toISOString(),
        alive: data.alive || DevIsAlive.NOMINAL,
      };

      console.log(data.tags);

      API.editDevice(dataFormatted).then((result) => {
        if (result.isSuccess) {
          enqueueSnackbar(t("create_device_form.edit_success"), {
            variant: "success",
          });
          fetchDevices();
        } else {
          enqueueSnackbar(t("create_device_form.edit_error"), {
            variant: "error",
          });
        }
      });
    } else {
      const dataFormatted = {
        ...data,
        tags: data.tags?.map((tag) => ({ id: 0, name: tag.label })),
        latitude: parseFloat(data.latitude),
        longitude: parseFloat(data.longitude),
        registrationTime: new Date().toISOString(),
        alive: DevIsAlive.NOMINAL, //value expected ?
      };

      API.createDevice(dataFormatted).then((result) => {
        if (result.isSuccess) {
          enqueueSnackbar(t("create_device_form.create_success"), {
            variant: "success",
          });
          fetchDevices();
        } else {
          enqueueSnackbar(t("create_device_form.create_error"), {
            variant: "error",
          });
        }
      });
    }
    onClose();
  };

  const checkDeviceNameExists = (name, plantId, excludeId = null) => {
    try {
      const { devices } = useDevicesStore.getState();
      const nameExists = devices.some(
        (device) =>
          device.name.toLowerCase() === name.toLowerCase() &&
          device.id !== excludeId &&
          device.plant === plantId
      );
      return nameExists;
    } catch (error) {
      console.error("Error checking device name:", error);
      return false;
    }
  };

  return (
    <Box p={4}>
      <Box bg="white" p={4}>
        <Heading size="md" pb={"20px"} textAlign={"center"}>
          {deviceData
            ? t("create_device_form.edit_device")
            : t("create_device_form.add_device")}
        </Heading>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CreateDevicePlant
            control={control}
            errors={errors}
            isReadOnly={readOnly}
          />
          <FormInput
            name="name"
            placeholder={t("create_device_form.name")}
            control={control}
            register={register}
            errors={errors}
            validationRules={{
              required: t("errors.required"),
              validate: {
                nameIsUnique(value) {
                  const selectedPlant = getValues("plant");
                  console.log(selectedPlant);

                  const nameExists = checkDeviceNameExists(
                    value,
                    selectedPlant,
                    deviceData?.id
                  );
                  return !nameExists || t("create_device_form.name_exists");
                },
              },
            }}
            isReadOnly={readOnly}
          />

          <FormInput
            name="location"
            placeholder={t("create_device_form.location")}
            control={control}
            register={register}
            errors={errors}
            validationRules={{ required: t("errors.required") }}
            isReadOnly={readOnly}
          />

          <FormInput
            name="latitude"
            placeholder={t("create_device_form.latitude")}
            control={control}
            register={register}
            errors={errors}
            validationRules={{
              required: t("errors.required"),
              validate: {
                isValidLatitude: (value) =>
                  /^-?([1-8]?[0-9](\.\d+)?|90(\.0+)?)$/.test(value) ||
                  t("errors.invalid_latitude"),
              },
            }}
            isReadOnly={readOnly}
          />

          <FormInput
            name="longitude"
            placeholder={t("create_device_form.longitude")}
            control={control}
            register={register}
            errors={errors}
            validationRules={{
              required: t("errors.required"),
              validate: {
                isValidLongitude: (value) =>
                  /^-?((1[0-7][0-9]|[1-9]?[0-9])(\.\d+)?|180(\.0+)?)$/.test(
                    value
                  ) || t("errors.invalid_longitude"),
              },
            }}
            isReadOnly={readOnly}
          />

          <FormInput
            name="version"
            placeholder={t("create_device_form.version")}
            control={control}
            register={register}
            errors={errors}
            validationRules={{
              required: t("errors.required"),
              validate: {
                isVersion: (value) =>
                  !isNaN(parseFloat(value)) ||
                  value.includes(".") ||
                  t("errors.invalid_version"),
              },
            }}
            isReadOnly={readOnly}
          />
          <CreateDeviceTag
            control={control}
            errors={errors}
            isReadOnly={readOnly}
          />
          <FormButtons
            onClose={onClose}
            isUpdateForm={!!deviceData}
            setReadOnly={setReadOnly}
            isReadOnly={readOnly}
          />
        </form>
      </Box>
    </Box>
  );
}

export default CreateDevice;
