import React, { useEffect, useState } from "react";
import { Box, Text, Icon, Tooltip, Grid, GridItem } from "@chakra-ui/react";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import CreateDevice from "./CreateDevice";
import SliderPopup from "components/slider/SliderPopup";

export const DevIsAlive = {
  NOMINAL: 0,
  PENDING: 1,
  IGNORED: 2,
  NOT_RESPONDING: 3,
  ERROR: 4,
};

const cardColors = {
  [DevIsAlive.NOMINAL]: "green.500",
  [DevIsAlive.PENDING]: "orange.500",
  [DevIsAlive.IGNORED]: "blue.300",
  [DevIsAlive.NOT_RESPONDING]: "black",
  [DevIsAlive.ERROR]: "red.500",
};

const cardTextColors = {
  [DevIsAlive.NOMINAL]: "black",
  [DevIsAlive.PENDING]: "black",
  [DevIsAlive.IGNORED]: "black",
  [DevIsAlive.NOT_RESPONDING]: "white",
  [DevIsAlive.ERROR]: "black",
};

const search = (text, data) => {
  if (!text) return data;

  return data.filter((device) => {
    return (
      device.name.toLowerCase().includes(text.toLowerCase()) ||
      device.location.toLowerCase().includes(text.toLowerCase()) ||
      device.version.toLowerCase().includes(text.toLowerCase()) ||
      device.registrationDate.toLowerCase().includes(text.toLowerCase()) ||
      device.tags.some((tag) =>
        tag.name.toLowerCase().includes(text.toLowerCase())
      )
    );
  });
};

function DeviceCardGrid({ data, searchText }) {
  const [filteredData, setFilteredData] = useState(data);

  const [isModalOpen, setIsModalOpen] = useState(
    new Array(data.length).fill(false)
  );
  const [selectedDeviceIndex, setSelectedDeviceIndex] = useState(null);

  const handleOperatorSelect = (index) => {
    setSelectedDeviceIndex(index);
    setIsModalOpen((prev) =>
      prev.map((isOpen, i) => (i === index ? true : isOpen))
    );
  };

  const handleCloseModal = () => {
    setIsModalOpen((prev) =>
      prev.map((isOpen, i) => (i === selectedDeviceIndex ? false : isOpen))
    );
    setSelectedDeviceIndex(null);
  };

  useEffect(() => {
    setFilteredData(search(searchText, data));
  }, [searchText, data]);

  useEffect(() => {
    setIsModalOpen(new Array(filteredData.length).fill(false));
  }, [filteredData.length]);

  return (
    <Grid
      templateColumns="repeat(auto-fill, minmax(120px, 1fr))"
      gap={4}
      m={"20px"}
    >
      {filteredData.map((device, index) => (
        <GridItem key={index}>
          <Box
            key={index}
            borderWidth="1px"
            borderRadius="lg"
            overflow="hidden"
            bg={cardColors[device.alive]}
            p={"5px"}
            textAlign="center"
            boxShadow="md"
            border="1px"
            width={"120px"}
            height={"120px"}
            onClick={() => {
              handleOperatorSelect(index);
            }}
            cursor="pointer"
          >
            <Text
              fontSize="lg"
              fontWeight="bold"
              color={cardTextColors[device.alive]}
            >
              {device.name}
            </Text>
            <Text color={cardTextColors[device.alive]}>
              {device.location}{" "}
              <Tooltip label={device.tags.map((tag) => tag.name).join(", ")}>
                <Icon color={cardTextColors[device.alive]} ml={"auto"}>
                  <SellOutlinedIcon />
                </Icon>
              </Tooltip>
            </Text>
            <Text color={cardTextColors[device.alive]} pt={"10px"}>
              {device.coordinates}
            </Text>

            <SliderPopup
              key={index}
              isOpen={isModalOpen[index]}
              onClose={handleCloseModal}
            >
              <CreateDevice onClose={handleCloseModal} deviceData={device} />
            </SliderPopup>
          </Box>
        </GridItem>
      ))}
    </Grid>
  );
}

export default DeviceCardGrid;
