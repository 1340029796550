import API from "api/API";
import { create } from "zustand";
import * as mqtt from "mqttManager/mqttManager";
import mapUserAttributes from "utils/mapUser";

export const useLoggedAccountStores = create((set) => ({
  roles: [],
  setRoles: (roles) => set({ roles: roles }),
}));

export const useKeycloak = create((set) => ({
  keycloak: [],
  setkeycloak: (keycloak) => set({ keycloak: keycloak }),
}));

export const useCallbacks = create((set, get) => ({
  callbacks: {},
  setCallbacks: (name, callback) => {
    set((state) => ({ callbacks: { ...state.callbacks, [name]: callback } }));
  },
}));

export const useMqtt = create((set, get) => ({
  isInitialized: false,
  init: (mqttConfig) => {
    const { isInitialized } = get();
    if (!isInitialized) {
      mqtt.connectMQTT(mqttConfig.url, mqttConfig);
      set((state) => ({ isInitialized: true }));
    }
  },
  clear: () => {
    mqtt.cleanTopics();
  },
  subscribe: (topic, callback) => {
    mqtt.subscribeToTopic(topic, callback);
  },
  unsubscribe: (topic) => {
    mqtt.unsubscribeFromTopic(topic);
  },
  close: () => {
    mqtt.disconnectMQTT();
  },
}));

export const useUsernameStore = create((set) => ({
  username: "",
  setUsername: (user) => set({ username: user }),
}));

export const useDevicesStore = create((set, get) => ({
  devices: [],
  isUpdating: false,
  get isDevicesUpdating() {
    return this.isUpdating;
  },
  fetchDevices: async () => {
    const { isUpdating } = get();
    if (!isUpdating) {
      set((state) => ({ isUpdating: true }));
      try {
        const result = await API.getAllDevices();
        if (result.isSuccess) {
          const { clear, subscribe } = useMqtt.getState();
          const { callbacks } = useCallbacks.getState();
          await clear();
          result.data.map(async (device) => {
            subscribe("device/" + device.name, callbacks.device);
          });
          set({ devices: result.data });
        } else {
          console.error("Failed to fetch events data");
        }
      } catch (error) {
        console.error("Error fetching operators:", error);
      }
      set((state) => ({ isUpdating: false }));
    }
  },
}));

export const useOperatorsStore = create((set, get) => ({
  operators: [],
  isUpdating: false,
  get isOperatorsUpdating() {
    return this.isUpdating;
  },
  fetchOperators: async () => {
    const { isUpdating } = get();
    if (!isUpdating) {
      set((state) => ({ isUpdating: true }));
      try {
        const result = await API.getAllOperators();
        if (result.isSuccess) {
          set({ operators: mapUserAttributes(result.data) });
        } else {
          console.error("Failed to fetch events data");
        }
      } catch (error) {
        console.error("Error fetching operators:", error);
      }
      set((state) => ({ isUpdating: false }));
    }
  },
}));

export const useGroupsStore = create((set, get) => ({
  groups: [],
  isUpdating: false,
  fetchGroups: async () => {
    const { isUpdating } = get();
    if (!isUpdating) {
      set((state) => ({ isUpdating: true }));
      try {
        const result = await API.getAllGroups();
        if (result.isSuccess) {
          set({ groups: result.data });
        } else {
          console.error("Failed to fetch events data");
        }
      } catch (error) {
        console.error("Error fetching operators:", error);
      }
      set((state) => ({ isUpdating: false }));
    }
  },
}));

export const useEventsStore = create((set, get) => ({
  events: [],
  isUpdating: false,
  get isEventsUpdating() {
    return this.isUpdating;
  },
  fetchEvents: async () => {
    const { isUpdating } = get();
    if (!isUpdating) {
      set((state) => ({ isUpdating: true }));
      try {
        const result = await API.getAllEvents();
        if (result.isSuccess) {
          set({ events: result.data });
        } else {
          console.error("Failed to fetch events data");
        }
      } catch (error) {
        console.error("Error fetching operators:", error);
      }
      set((state) => ({ isUpdating: false }));
    }
  },
}));

export const useMeasuresStore = create((set, get) => ({
  measures: [],
  isUpdating: false,
  fetchMeasures: async () => {
    const { isUpdating } = get();
    if (!isUpdating) {
      set((state) => ({ isUpdating: true }));
      try {
        const result = await API.getAllMeasures();
        if (result.isSuccess) {
          set({ measures: result.data });
        } else {
          console.error("Failed to fetch events data");
        }
      } catch (error) {
        console.error("Error fetching operators:", error);
      }
      set((state) => ({ isUpdating: false }));
    }
  },
  measuresComplete: [],
  fetchMeasuresComplete: async () => {
    const { isUpdating } = get();
    if (!isUpdating) {
      set((state) => ({ isUpdating: true }));
      try {
        const result = await API.getAllMeasuresComplete();
        if (result.isSuccess) {
          set({ measuresComplete: result.data });
        } else {
          console.error("Failed to fetch events data");
        }
      } catch (error) {
        console.error("Error fetching operators:", error);
      }
      set((state) => ({ isUpdating: false }));
    }
  },
}));
