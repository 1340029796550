import {
  Tbody,
  Tr,
  Td,
  Flex,
  Text,
  Icon,
  Box,
  Button,
  Tooltip,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

//per DeviceTableContent e OperatorsTableContent
const maxTagsLength = 20;

//per EventsTableContent
const bgColor = "pcr.200";
const hoverColor = "pcr.100";

export const formatDate = (dateString) => {
  const date =
    dateString instanceof Date && !isNaN(dateString)
      ? dateString
      : new Date(dateString);
  return format(date, "dd/MM/yyyy HH:mm");
};

const TableContent = ({
  page,
  prepareRow,
  textColor,
  isTicketOn,
  columnsList,
  pageType
}) => {
  const renderers = {
    date: (cell, textColor) => (
      <Text me="10px" color={textColor} fontSize="md" fontWeight="400">
        {formatDate(cell) || "-"}
      </Text>
    ),
    text: (cell, textColor) => (
      <Text me="10px" color={textColor} fontSize="md" fontWeight="400">
        {cell || "-"}
      </Text>
    ),
    textIcon: (cell, textColor) => (
      <Box display={"flex"}>
        <Text me="10px" color={textColor} fontSize="md" fontWeight="400">
          {cell || "-"}
        </Text>
        <Icon fontSize={"large"}>{cell.column.icon}</Icon>
      </Box>
    ),
    textList: (value, textColor) => {
      let result;
      if (value.length === 1) {
        return (
          <Tooltip label={result}>
            <Text me="10px" color={textColor} fontSize="md" fontWeight="400">
              {value || "-"}
            </Text>
          </Tooltip>
        );
      }
      result = value.join(", ");
      if (result.length > maxTagsLength) {
        let truncatedString = result.substring(0, maxTagsLength);
        const lastCommaIndex = truncatedString.lastIndexOf(",");
        return (
          <Tooltip label={result}>
            <Text me="10px" color={textColor} fontSize="md" fontWeight="400">
              {truncatedString.substring(0, lastCommaIndex) + " ..." || "-"}
            </Text>
          </Tooltip>
        );
      } else {
        return (
          <Text me="10px" color={textColor} fontSize="md" fontWeight="400">
            {result || "-"}
          </Text>
        );
      }
    },
    status: (value, textColor) => (
      <Text me="10px" color={textColor} fontSize="md" fontWeight="400">
        {value ? t(`operators_list.op_is_available.${value}`) : "-"}
      </Text>
    ),
  };

  const defaultRenderer = (value, textColor) => (
    <Text me="10px" color={textColor} fontSize="md" fontWeight="400">
      {value || "-"}
    </Text>
  );

  const formatValue = (cell) => {
    const renderer = renderers[cell.column.type] || defaultRenderer;
    return renderer(cell.value, textColor);
  };

  const columnsRenderers = {
    button: (cell, row) => (
      <Button
        bgColor={bgColor}
        _hover={{ bgColor: hoverColor }}
        ml={"5px"}
        textColor="black"
        onClick={() => {
          navigate("/events/form", {
            state: { eventID: row.original.id },
          });
        }}
      >
        {" "}
        {t(cell.Headers)}
      </Button>
    ),
  };

  const formatColumnsValue = (cell, row) => {
    const renderer = columnsRenderers[(cell, row)] || defaultRenderer;
    return renderer(cell.value, textColor);
  };

  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Tbody>
      {page.map((row) => {
        prepareRow(row);
        return (
          <Tr {...row.getRowProps()} key={row.id}>
            {row.cells.map((cell) => {
              let data = <Flex align="center">{formatValue(cell)}</Flex>;
              return (
                <Td
                  {...cell.getCellProps()}
                  key={cell.id}
                  fontSize={{ sm: "14px" }}
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor="transparent"
                >
                  {data}
                </Td>
              );
            })}
            <Td>
                  {columnsList[0].page === pageType && (
                    formatColumnsValue(columnsList, row))}
            </Td>
          </Tr>
        );
      })}
    </Tbody>
  );
};

export default TableContent;
