import React from "react";
import { FormControl, Input, FormErrorMessage } from "@chakra-ui/react";
import { Controller } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import Select from "react-select";

const inputBgColor = "#EEEEEE";
const inputPlaceholderColor = "gray";

const FormInput = ({
  name,
  type = "text",
  placeholder,
  control,
  register,
  errors,
  isDisabled = false,
  isPhoneInput = false,
  isSelect = false,
  selectOptions = [],
  validationRules = {},
  isReadOnly,
}) => {
  return (
    <FormControl p={"10px"} isInvalid={errors[name]}>
      {isPhoneInput ? (
        <Controller
          name={name}
          control={control}
          rules={validationRules}
          render={({ field }) => (
            <PhoneInput
              country={"it"}
              value={field.value}
              onChange={field.onChange}
              inputStyle={{ width: "100%", backgroundColor: inputBgColor }}
              buttonStyle={{ backgroundColor: inputBgColor }}
              disabled={isReadOnly}
            />
          )}
        />
      ) : isSelect ? (
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              options={selectOptions}
              isMulti
              menuPlacement="top"
              placeholder={placeholder}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: "black",
                },
              })}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  backgroundColor: inputBgColor,
                }),
              }}
              onChange={(selected) => field.onChange(selected)}
              isDisabled={isReadOnly}
            />
          )}
        />
      ) : (
        <Input
          focusBorderColor="black"
          bgColor={inputBgColor}
          name={name}
          type={type}
          placeholder={placeholder}
          isDisabled={isDisabled}
          isReadOnly={isReadOnly}
          {...register(name, validationRules)}
          sx={{
            "::placeholder": {
              color: inputPlaceholderColor,
            },
          }}
        />
      )}
      <FormErrorMessage>
        {errors[name] && errors[name].message}
      </FormErrorMessage>
    </FormControl>
  );
};

export default FormInput;
