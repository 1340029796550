import {
  Flex,
  Table,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useState, useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
  useFilters,
} from "react-table";
import Card from "components/card/Card";
//import EventsTableContent from "../../views/events/components/EventsTableContent";
import Pagination from "components/table/Pagination";
import { useTranslation } from "react-i18next";
import ColumnsButton from "components/filters/ColumnsButton";
import FilterButton from "components/filters/FilterButton";
import ExportButton from "components/filters/ExportButton";
import TableContent from "components/filters/TableContent";
import { column } from "stylis";

export default function FiltersTable(props) {
  const {
    tableData,
    searchText,
    isFiltersOn,
    isMapDashboard,
    isTicketOn,
    pageType,
  } = props;
  const [tableDataString, setTableDataString] = useState([]);

  const [columnsData, setColumnsData] = useState([]);

  const columnsList = [
    {
      Header: "events_list.create_ticket",
      accessor: "Button",
      type: "text",
      page: "events_list",
    },
  ];

  useEffect(() => {
    if (tableData.length > 0) {
      let properties = Object.keys(tableData[0]);
      let cols = properties.map((key) => ({
        Header: key,
        accessor: key,
        type: "text",
      }));
      /*columnsList.forEach(function(columList, index){
        cols.push({
          Header: columList.Header, // Nome della colonna
          accessor: columList.accessor, // Nome dell'accessor, anche se non è presente nei dati
          type: columList.type,
          page: columList.page
        });
      });*/
      setColumnsData(cols);

      let obj = tableData;
      for (let i = 0; i < obj.length; i++) {
        for (let key in obj[i]) {
          if (obj[i].hasOwnProperty(key)) {
            // Converte il valore in una stringa usando toString()
            obj[i][key] = obj[i][key] ? obj[i][key].toString() : null;
          }
        }
      }
      setTableDataString(obj);
    }
  }, [tableData]);

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableDataString, [tableDataString]);
  const { t } = useTranslation();

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 10 }, // Set initial page size
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    allColumns,
    toggleHideColumn,
    state: { pageIndex, pageSize },
    setGlobalFilter,
    rows,
  } = tableInstance;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  useEffect(() => {
    setGlobalFilter(searchText || undefined);
  }, [searchText, setGlobalFilter]);

  useEffect(() => {
    if (isMapDashboard) {
      headerGroups.forEach((headers) => {
        headers.headers.forEach((header) => {
          if (
            header.id !== "eventTime" &&
            header.id !== "action" &&
            header.id !== "description"
          )
            toggleHideColumn(header.id, true);
        });
      });
    }
  }, [toggleHideColumn, isMapDashboard, headerGroups]);

  /*const utilsMapping = ({ item }) => {
    switch(item.type){
      case 'button'
    }
    <Flex
      justify="space-between"
      align="center"
      fontSize={{ sm: "12px", lg: "14px" }}
      color="gray.400"
    >
      {t("events_list.create_ticket")}
    </Flex>;
  };*/

  return (
    <Card
      direction="column"
      w={isMapDashboard ? "50% " : "100%"}
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      {!isFiltersOn && (
        <Flex padding={(0, 10)}>
          <ColumnsButton
            allColumns={allColumns}
            toggleHideColumn={toggleHideColumn}
            pageType={pageType}
          />
          <FilterButton
            allColumns={allColumns}
            tableData={tableData}
            pageType={pageType}
          />
          <ExportButton columns={columns} rows={rows} allColumns={allColumns} />
        </Flex>
      )}

      <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
        <Thead>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
              {headerGroup.headers.map((column) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={column.id}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: "12px", lg: "14px" }}
                    color="gray.400"
                  >
                    {t(`${pageType}.${column.Header}`)}
                  </Flex>
                </Th>
              ))}
              <Th>
                {columnsList.map(function (columList, index) {
                  return(
                  columList.page === pageType && <Flex
                    key={index}
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: "12px", lg: "14px" }}
                    color="gray.400"
                  >
                    {t(columList.Header)}
                  </Flex>);
                })}
              </Th>
            </Tr>
          ))}
        </Thead>
        <TableContent
          page={page}
          prepareRow={prepareRow}
          textColor={textColor}
          isTicketOn={isTicketOn}
          columnsList={columnsList}
          pageType={pageType}
          {...getTableBodyProps()}
        />
      </Table>

      <Pagination
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageOptions={pageOptions}
        pageCount={pageCount}
        gotoPage={gotoPage}
        nextPage={nextPage}
        previousPage={previousPage}
        setPageSize={setPageSize}
        pageIndex={pageIndex}
        pageSize={pageSize}
      />
    </Card>
  );
}
