/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useEventsStore } from "contexts/stores";

// Chakra imports
import { Box, Flex, Text, useBreakpointValue } from "@chakra-ui/react";
// Assets

// Custom components

import React, { useEffect } from "react";
import EventsTable from "./components/EventsTable";
import { columnsData } from "views/events/variables/columnsData";
import MobileEventsTable from "./components/MobileEventsTable";
import { useTranslation } from "react-i18next";
import LoadingSpinner from "components/generic/LoadingSpinner";

export default function EventsPage() {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { events, isUpdating, fetchEvents } = useEventsStore();
  const { t } = useTranslation();

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  return (!events || events.length === 0) && isUpdating ? (
    <LoadingSpinner />
  ) : (
    <Box bgColor="white" padding="15px" shadow={"lg"}>
      {events !== undefined && events !== null ? (
        isMobile ? (
          <MobileEventsTable data={events} />
        ) : (
          <EventsTable columnsData={columnsData} tableData={events} />
        )
      ) : (
        <>
          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            w={"100%"}
            minH={"100vh"}
          >
            <Flex alignItems={"center"}>
              <Text pl="0.5rem" fontSize="4xl" as={"b"}>
                {t("empty_data")}
              </Text>
            </Flex>
          </Flex>
        </>
      )}
    </Box>
  );
}
