import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Controller } from "react-hook-form";
import { FormControl, FormErrorMessage } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import API from "api/API";

const inputBgColor = "#EEEEEE";

function CreateDevicePlant({ control, errors, defaultValue, isReadOnly }) {
  const { t } = useTranslation();
  const [plantOptions, setPlantOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPlants = async () => {
      try {
        const response = await API.getAllPlants();
        if (Array.isArray(response.data)) {
          setPlantOptions(
            response.data.map((plant) => ({
              value: plant.id,
              label: plant.kName,
            }))
          );
        } else {
          setPlantOptions([]);
          setError(new Error("Invalid data format"));
        }
      } catch (err) {
        setError(err);
        setPlantOptions([]);
      } finally {
        setLoading(false);
      }
    };

    fetchPlants();
  }, []);

  return (
    <FormControl p="10px" isInvalid={errors.plant}>
      <Controller
        name="plant"
        control={control}
        rules={{ required: t("create_device_form.plant_required") }}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <Select
            isDisabled={isReadOnly}
            onChange={(selectedOption) => onChange(selectedOption?.value)}
            onBlur={onBlur}
            value={
              plantOptions.find((option) => option.value === value) || null
            }
            ref={ref}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "black",
              },
            })}
            styles={{
              control: (baseStyles) => ({
                ...baseStyles,
                backgroundColor: inputBgColor,
              }),
            }}
            options={plantOptions}
            menuPlacement="bottom"
            isLoading={loading}
            placeholder={t("create_device_form.plant")}
          />
        )}
      />
      <FormErrorMessage>
        {errors.plant && errors.plant.message}
      </FormErrorMessage>
    </FormControl>
  );
}

export default CreateDevicePlant;
