import React from "react";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";
import italyTopoJson from "../italyTopo"; // TopoJSON dell'Italia

const cities = [
  { name: "Rome", coordinates: [12.4964, 41.9028] },
  { name: "Milan", coordinates: [9.19, 45.4642] },
  { name: "Naples", coordinates: [14.2681, 40.8518] },
  { name: "Turin", coordinates: [7.6869, 45.0703] },
  { name: "Palermo", coordinates: [13.3615, 38.1157] },
  { name: "Genoa", coordinates: [8.9463, 44.4056] },
  { name: "Bologna", coordinates: [11.3426, 44.4949] },
  { name: "Florence", coordinates: [11.2558, 43.7696] },
  { name: "Bari", coordinates: [16.8719, 41.1171] },
  { name: "Catania", coordinates: [15.0873, 37.5023] },
];

const MapComponent = ({ devices }) => {
  return (
    <div
      style={{
        position: "relative",
        height: "90vh",
        width: "50%",
      }}
    >
      <ComposableMap
        projection="geoMercator"
        projectionConfig={{ scale: 3000, center: [12.5674, 41.8719] }}
        style={{ width: "100%", height: "100%" }}
      >
        <Geographies geography={italyTopoJson} width={"100%"} height={"100%"}>
          {({ geographies }) =>
            geographies.map((geo) => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                style={{
                  default: {
                    fill: "#ECEFF1",
                    stroke: "#607D8B",
                    strokeWidth: 0.75,
                  },
                  hover: {
                    fill: "#CFD8DC",
                    stroke: "#607D8B",
                    strokeWidth: 0.75,
                  },
                  pressed: {
                    fill: "#FF5722",
                    stroke: "#607D8B",
                    strokeWidth: 0.75,
                  },
                }}
              />
            ))
          }
        </Geographies>
        {devices.map((device, i) => (
          <Marker key={i} coordinates={[device.longitude, device.latitude]}>
            <circle
              r={10}
              fill={device.alive === 0 ? "#01B574" : "#FFB547"}
              stroke="#fff"
              strokeWidth={2}
            />
          </Marker>
        ))}
        {cities.map((city, i) => (
          <Marker key={i} coordinates={city.coordinates}>
            <text
              textAnchor="middle"
              y={0}
              style={{ fontFamily: "system-ui", fill: "#5D5A6D" }}
              fontSize={"20px"}
            >
              {city.name}
            </text>
          </Marker>
        ))}
      </ComposableMap>
      <div
        id="custom-attribution"
        style={{
          position: "absolute",
          bottom: 0,
          right: 0,
          padding: "20px",
          paddingTop: "0px",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          zIndex: 1000,
          fontSize: "12px",
        }}
      >
        <a
          href="https://www.openstreetmap.org/copyright"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "none", color: "#000" }}
        >
          © OpenStreetMap contributors
        </a>
      </div>
    </div>
  );
};

export default MapComponent;
