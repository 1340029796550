import React from "react";
import {
    Button,
    Text,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    PopoverHeader,
    PopoverBody,
    Portal,
} from "@chakra-ui/react";
import ColumnFilter from "views/events/components/DefaultColumnFilter";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useTranslation } from "react-i18next";
const bgColor = "pcr.200";
const hoverColor = "pcr.100";

export default function FilterButton({allColumns, tableData, pageType}) {

    const { t } = useTranslation();

    return(
        <Popover>
        <PopoverTrigger>
          <Button
            bgColor={bgColor}
            _hover={{ bgColor: hoverColor }}
            ml={"5px"}
          >
            {" "}
            <FilterListIcon />{" "}
            <Text pl={"2px"}>{t("column_filter.button")}</Text>
          </Button>
        </PopoverTrigger>
        <Portal>
          <PopoverContent borderColor={"black"}  width="400px">
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverHeader>{t("column_filter.title")}</PopoverHeader>
            <PopoverBody>
              <ColumnFilter
                columns={allColumns.filter((col) => col.canFilter)}
                rows={tableData}
                pageType = {pageType}
              />
            </PopoverBody>
          </PopoverContent>
        </Portal>
      </Popover>
    );
}