import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import KeycloakLogin from "keycloakLogin";
import useSignalR from "utils/UseSignalR";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEventsStore } from "contexts/stores";
import { mqttConfig } from "mqttManager/config";
import { useMqtt } from "contexts/stores";

import i18n from "./i18n";
import { useCallbacks } from "contexts/stores";

const App = () => {
  const { signalRMessages } = useSignalR(process.env.REACT_APP_NOTIFICATIONHUB);
  const [mqttMessages, setMqttMessages] = useState([]);
  const [messages, setMessages] = useState([]);
  const { fetchEvents } = useEventsStore((state) => ({
    fetchEvents: state.fetchEvents,
  }));

  const { init } = useMqtt();
  const { setCallbacks } = useCallbacks();

  useEffect(() => {
    if (signalRMessages && signalRMessages.length > 0) {
      var mes = [...messages, signalRMessages[signalRMessages.length - 1]];
      setMessages(mes);
    }
  }, [signalRMessages]);

  useEffect(() => {
    if (mqttMessages && mqttMessages.length > 0) {
      var mes = [...messages, mqttMessages[mqttMessages.length - 1]];
      setMessages(mes);
    }
  }, [mqttMessages]);

  useEffect(() => {
    setCallbacks("device", (message) => {
      var mes = [...messages, message];
      setMqttMessages(mes);
    });
    init(mqttConfig);
  }, [init]);

  useEffect(() => {
    if (Notification.permission === "default") {
      Notification.requestPermission().then((permission) => {
        if (permission !== "granted") {
          console.warn("Permission for notifications was denied.");
        }
      });
    }

    if (messages && messages.length > 0) {
      const mess = JSON.parse(messages[messages.length - 1]);
      if (mess.type === "event") {
        toast(`${mess.device}: ${mess.description}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onOpen: () => {
            const sound = new Audio("/notification1.wav");
            sound.play();
          },
        });

        if (Notification.permission === "granted") {
          new Notification(mess.device, {
            body: mess.description,
            icon: "/favicon.ico",
          });
        }

        fetchEvents();
      }
    }
  }, [messages, fetchEvents]);

  return (
    <ChakraProvider theme={theme}>
      <React.StrictMode>
        <KeycloakLogin tab="home" />
        <ToastContainer />
      </React.StrictMode>
    </ChakraProvider>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
