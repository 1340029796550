import React, { useMemo } from "react";
import { ResponsivePie } from "@nivo/pie";
import { useTranslation } from "react-i18next";
import { Box, Text, useBreakpointValue } from "@chakra-ui/react";

const pieColors = ["#01B574", "#FFB547", "#3965FF", "#000000", "#EE5D50"];
const pieColorsOperators = ["#01B574", "#FFB547", "#EE5D50", "#000000"];

function PieChart({ devicesData, operatorsData }) {
  const { t } = useTranslation();
  const isMobile = useBreakpointValue({ base: true, md: false });

  const transformDevicesData = useMemo(
    () => (data) => {
      const possibleStates = [0, 1, 2, 3, 4]; // Lista delle categorie possibili
      const groupedData = data.reduce((acc, device) => {
        acc[device.alive] = (acc[device.alive] || 0) + 1;
        return acc;
      }, {});

      const total = Object.values(groupedData).reduce(
        (sum, value) => sum + value,
        0
      );

      // Assicurati che tutte le categorie siano incluse
      return possibleStates.map((state) => {
        const value = groupedData[state] || 0;
        const percentage = total > 0 ? ((value / total) * 100).toFixed(2) : 0;
        return {
          id: `${t(`pie_chart.dev_is_alive.${state}`)}\n ${percentage}%`,
          label: `${t(
            `pie_chart.dev_is_alive.${state}`
          )}\n ${percentage}% (${value})`,
          value: value,
          color: pieColors[state] || "#ccc", // Colore di default se non definito
        };
      });
    },
    [devicesData, t]
  );

  const transformOperatorsData = useMemo(
    () => (data) => {
      const possibleStates = [0, 1, 2, 3]; // Lista delle categorie possibili
      const groupedData = data.reduce((acc, operator) => {
        acc[operator.availability] = (acc[operator.availability] || 0) + 1;
        return acc;
      }, {});

      const total = Object.values(groupedData).reduce(
        (sum, value) => sum + value,
        0
      );

      // Assicurati che tutte le categorie siano incluse
      return possibleStates.map((state) => {
        const value = groupedData[state] || 0;
        const percentage = total > 0 ? ((value / total) * 100).toFixed(2) : 0;
        return {
          id: `${t(`pie_chart.op_is_available.${state}`)}\n ${percentage}%`,
          label: `${t(
            `pie_chart.op_is_available.${state}`
          )}\n ${percentage}% (${value})`,
          value: value,
          color: pieColors[state] || "#ccc", // Colore di default se non definito
        };
      });
    },
    [operatorsData, t]
  );

  const formattedData = devicesData
    ? transformDevicesData(devicesData)
    : transformOperatorsData(operatorsData);

  return (
    <Box width={"100%"} height={{ base: "200px", md: "300px", xl: "400px" }}>
      <Text align={"center"} fontSize={"2xl"} fontFamily={"sans-serif"}>
        {" "}
        {devicesData ? t("pie_chart.device_title") : t("pie_chart.op_title")}
      </Text>
      <ResponsivePie
        fit={false}
        arcLinkLabelsStraightLength={5}
        arcLinkLabel={(d) => {
          return d.value > 0 && isMobile ? `${d.id}` : false;
        }}
        arcLinkLabelsThickness={(d) => {
          return d.value > 0 && isMobile ? 2 : 0;
        }}
        enableArcLabels={isMobile}
        arcLinkLabelsColor={(d) => {
          return d.value > 0 && isMobile ? "black" : "white";
        }}
        sortByValue={true}
        data={formattedData}
        margin={
          isMobile
            ? { top: 40, bottom: 70, left: 20, right: 20 }
            : { top: 40, right: 80, bottom: 80, left: 0 }
        }
        innerRadius={0}
        padAngle={0.7}
        cornerRadius={3}
        colors={devicesData ? pieColors : pieColorsOperators}
        borderWidth={1}
        borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
        radialLabelsSkipAngle={10}
        sliceLabelsSkipAngle={10}
        animate={false}
        motionStiffness={90}
        motionDamping={15}
        legends={
          !isMobile
            ? [
                {
                  itemBackground: "rgba(255, 255, 255, 0.9)",
                  anchor: isMobile ? "bottom" : "bottom-right",
                  direction: "column",
                  justify: false,
                  translateX: isMobile ? 0 : 50,
                  translateY: isMobile ? 70 : 56,
                  itemWidth: 190,
                  itemHeight: isMobile ? 20 : 36,
                  itemTextColor: "#333",
                  itemFontSize: "14px",
                  itemFontWeight: "bold",
                  symbolSize: 12,
                  symbolShape: "circle",
                  itemOpacity: 1,
                  padding: 5,
                  effects: [
                    {
                      on: "hover",
                      style: {
                        itemBackground: "rgba(0, 0, 0, 0.2)",
                        symbolSize: 15,
                      },
                    },
                  ],
                },
              ]
            : undefined
        }
      />
    </Box>
  );
}

export default PieChart;
