import React from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Textarea,
  FormErrorMessage,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

const inputBgColor = "#EEEEEE";
const inputPlaceholderColor = "gray";

const CustomFormControl = ({
  id,
  name,
  type,
  validation,
  defaultValue,
  isTextArea,
  translationPrefix,
  legend,
  isReadOnly,
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const { t } = useTranslation();

  return (
    <FormControl isInvalid={errors[name]} p={"10px"}>
      {legend !== false && (
        <FormLabel htmlFor={id}>{t(`${translationPrefix}.${name}`)}</FormLabel>
      )}
      {isTextArea ? (
        <Textarea
          readOnly={isReadOnly}
          id={id}
          name={name}
          placeholder={t(`${translationPrefix}.${name}`)}
          {...register(name, validation)}
          defaultValue={defaultValue}
          mb={"20px"}
          bgColor={inputBgColor}
          sx={{
            "::placeholder": {
              color: inputPlaceholderColor,
            },
          }}
        />
      ) : (
        <Input
          readOnly={isReadOnly}
          id={id}
          name={name}
          type={type}
          placeholder={t(`${translationPrefix}.${name}`)}
          {...register(name, validation)}
          defaultValue={defaultValue}
          bgColor={inputBgColor}
          sx={{
            "::placeholder": {
              color: inputPlaceholderColor,
            },
          }}
        />
      )}
      <FormErrorMessage>
        {errors[name] && errors[name].message}
      </FormErrorMessage>
    </FormControl>
  );
};

export default CustomFormControl;
