import { useTranslation } from "react-i18next";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {
    Button,
    Text,
} from "@chakra-ui/react";
import { formatDate } from "views/events/components/EventsTableContent";

const bgColor = "pcr.200";
const hoverColor = "pcr.100";

export default function ExportButton({columns, rows, allColumns}){

    const { t } = useTranslation();

    const exportToCSV = (columns, data) => {
    const visibleColumns = columns.filter((column) =>
      allColumns.some((col) => col.isVisible && col.Header === column.Header)
    );

    const convertToCSV = (data, columns) => {
      const header = columns
        .map((col) => t(`events_list.${col.Header}`))
        .join(",");
      const rows = data.map((row) =>
        columns
          .map((col) => {
            const value = row.values[col.accessor];
            if (col.type === "date") {
              return formatDate(value);
            }
            return value;
          })
          .join(",")
      );
      return [header, ...rows].join("\n");
    };

    const csv = convertToCSV(data, visibleColumns);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    // Create a link element and simulate a click to download the file
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    };

    return(
        <Button
            onClick={() => exportToCSV(columns, rows)}
            bgColor={bgColor}
            _hover={{ bgColor: hoverColor }}
            ml={"5px"}
          >
            <FileDownloadIcon />
            <Text pl={"2px"}>{t("export")}</Text>
        </Button>
    );
}