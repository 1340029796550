/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// Chakra imports
import {
  Box,
  Collapse,
  Flex,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
// Assets

// Custom components
import React, { useEffect, useState } from "react";
import EventsTable from "views/events/components/EventsTable";
import { columnsData } from "views/events/variables/columnsData";

import API from "../../api/API";
import PieChart from "./components/PieChart";
import { useTranslation } from "react-i18next";
import MapComponent from "./components/DevicesMap";
import mapUserAttributes from "utils/mapUser";
import MobileEventsTable from "../events/components/MobileEventsTable";
import ToggleViewSwitch from "components/switch/ToggleViewSwitch";
import {
  useEventsStore,
  useOperatorsStore,
  useDevicesStore,
} from "contexts/stores";
import LoadingSpinner from "components/generic/LoadingSpinner";

export default function Dashboard() {
  const { t } = useTranslation();
  const [isMapVisible, setIsMapVisible] = useState(true);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const { events, isEventsUpdating, fetchEvents } = useEventsStore();
  const { operators, isOperatorsUpdating, fetchOperators } =
    useOperatorsStore();
  const { devices, isDevicesUpdating, fetchDevices } = useDevicesStore();

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  useEffect(() => {
    fetchOperators();
  }, [fetchOperators]);

  useEffect(() => {
    fetchDevices();
  }, [fetchDevices]);

  return isEventsUpdating || isOperatorsUpdating || isDevicesUpdating ? (
    <LoadingSpinner />
  ) : events?.length > 0 || operators?.length > 0 || devices?.length > 0 ? (
    <Box bgColor="white" padding="15px" shadow={"lg"}>
      <Text
        align={"center"}
        fontSize={{ base: "2xl", md: "4xl" }}
        fontFamily={"sans-serif"}
      >
        {t("dashboard_title")}
      </Text>
      <ToggleViewSwitch
        isListTable={isMapVisible}
        setIsListTable={setIsMapVisible}
      />

      <Collapse in={!isMapVisible}>
        <Flex
          flexDirection={{
            base: "column",
            xl: "row",
          }}
          justifyContent="space-around"
          alignItems="center"
          width="100%"
          wrap="wrap"
        >
          <Flex width={{ base: "100%", xl: "45%" }} mb={{ base: 2, md: 5 }}>
            <PieChart devicesData={devices} />
          </Flex>
          <Flex width={{ base: "100%", xl: "45%" }} mb={{ base: 2, md: 5 }}>
            <PieChart operatorsData={operators} />
          </Flex>
        </Flex>
      </Collapse>

      {isMapVisible && (
        <Flex flexDirection={{ base: "column", xl: "row" }} alignItems="center">
          <MapComponent devices={devices} />
          <Flex
            flexDirection="column"
            justifyContent="space-around"
            alignItems="center"
            width="50%"
            wrap="wrap"
          >
            <Flex width="100%" mb={{ base: 2, md: 5 }}>
              <PieChart devicesData={devices} />
            </Flex>
            <Flex width="100%" mb={{ base: 2, md: 5 }}>
              <PieChart operatorsData={operators} />
            </Flex>
          </Flex>
        </Flex>
      )}
      {isMobile ? (
        <MobileEventsTable data={events} />
      ) : (
        <EventsTable
          columnsData={columnsData}
          tableData={events}
          isDashboard={true}
        />
      )}
    </Box>
  ) : (
    <Box bgColor="white" padding="15px" shadow={"lg"}>
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        w={"100%"}
        minH={"100vh"}
      >
        <Flex alignItems={"center"}>
          <Text pl="0.5rem" fontSize="4xl" as={"b"}>
            {t("empty_data")}
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
}
