/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2023 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Box,
  Collapse,
  Flex,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
// Custom components

import { SearchBar } from "components/navbar/searchBar/SearchBar";
import React, { useEffect, useState } from "react";
import OperatorCardGrid from "./components/OperatorCardGrid";
import OperatorsTable from "./components/OperatorsTable";
import { columnsData } from "./variables/columnsData";
import { useOperatorsStore } from "contexts/stores";
import { useTranslation } from "react-i18next";
import ToggleViewSwitch from "components/switch/ToggleViewSwitch";
import LoadingSpinner from "components/generic/LoadingSpinner";

export default function OperatorsPage() {
  const [isListTable, setIsListTable] = useState(false);
  const [searchText, setSearchText] = useState("");
  const { operators, isUpdating, fetchOperators } = useOperatorsStore();
  const { t } = useTranslation();

  const isMobile = useBreakpointValue(
    { base: true, md: false },
    {
      fallback: "md",
    }
  );

  useEffect(() => {
    fetchOperators();
  }, [fetchOperators]);

  return isUpdating && (!operators || operators.length === 0) ? (
    <LoadingSpinner />
  ) : (
    <>
      {operators.length !== 0 ? (
        <Box bgColor="white" padding="15px" shadow={"lg"}>
          <Flex>
            <SearchBar setSearchText={setSearchText} />
            <ToggleViewSwitch
              isListTable={isListTable}
              setIsListTable={setIsListTable}
            />
          </Flex>{" "}
          {isMobile ? (
            <OperatorCardGrid data={operators} searchText={searchText} />
          ) : (
            <>
              <Collapse in={isListTable}>
                <OperatorsTable
                  columnsData={columnsData}
                  tableData={[...operators]}
                  searchText={searchText}
                />
              </Collapse>
              <Collapse in={!isListTable}>
                {" "}
                <OperatorCardGrid data={operators} searchText={searchText} />
              </Collapse>
            </>
          )}
        </Box>
      ) : (
        <Box bgColor="white" padding="15px" shadow={"lg"}>
          <Flex
            justifyContent={"center"}
            alignItems={"center"}
            w={"100%"}
            minH={"100vh"}
          >
            <Flex alignItems={"center"}>
              <Text pl="0.5rem" fontSize="4xl" as={"b"}>
                {t("empty_data")}
              </Text>
            </Flex>
          </Flex>
        </Box>
      )}{" "}
    </>
  );
}
