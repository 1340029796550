// DeviceTableBody.js
import {
  Tbody,
  Tr,
  Td,
  Flex,
  Text,
  Icon,
  Box,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { format } from "date-fns";

import { useTranslation } from "react-i18next";
import SliderPopup from "components/slider/SliderPopup";
import EventForm from "./EventForm";

const bgColor = "pcr.200";
const hoverColor = "pcr.100";

export const formatDate = (dateString) => {
  const date =
    dateString instanceof Date && !isNaN(dateString)
      ? dateString
      : new Date(dateString);
  return format(date, "dd/MM/yyyy HH:mm");
};

const EventsTableContent = ({ page, prepareRow, textColor }) => {
  const renderers = {
    date: (cell, textColor) => (
      <Text me="10px" color={textColor} fontSize="md" fontWeight="400">
        {formatDate(cell.value) || "-"}
      </Text>
    ),
    text: (cell, textColor) => (
      <Text me="10px" color={textColor} fontSize="md" fontWeight="400">
        {cell.value || "-"}
      </Text>
    ),
    textIcon: (cell, textColor) => (
      <Box display={"flex"}>
        <Text me="10px" color={textColor} fontSize="md" fontWeight="400">
          {cell.value || "-"}
        </Text>
        <Icon fontSize={"large"}>{cell.column.icon}</Icon>
      </Box>
    ),
  };

  const defaultRenderer = (value, textColor) => (
    <Text me="10px" color={textColor} fontSize="md" fontWeight="400">
      {value || "-"}
    </Text>
  );

  const formatValue = (cell) => {
    const renderer = renderers[cell.column.type] || defaultRenderer;
    return renderer(cell, textColor);
  };

  const { t } = useTranslation();
  const eventDisclosure = useDisclosure();

  return (
    <Tbody>
      {page.map((row, index) => {
        prepareRow(row);
        return (
          <Tr {...row.getRowProps()} key={index}>
            {row.cells.map((cell, index) => {
              let data = <Flex align="center">{formatValue(cell)}</Flex>;
              return (
                <Td
                  {...cell.getCellProps()}
                  key={index}
                  fontSize={{ sm: "14px" }}
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor="transparent"
                >
                  {data}
                </Td>
              );
            })}
            <Td>
              <Button
                bgColor={bgColor}
                _hover={{ bgColor: hoverColor }}
                ml={"5px"}
                textColor="black"
                onClick={eventDisclosure.onOpen}
              >
                {" "}
                {t("events_list.handle_event")}
              </Button>
              <SliderPopup
                isOpen={eventDisclosure.isOpen}
                onClose={eventDisclosure.onClose}
              >
                <EventForm
                  onClose={eventDisclosure.onClose}
                  eventID={row.original.id}
                />
              </SliderPopup>
            </Td>
          </Tr>
        );
      })}
    </Tbody>
  );
};

export default EventsTableContent;
