// DeviceTableBody.js
import { Tbody, Tr, Td, Flex, Text, Tooltip } from "@chakra-ui/react";
import { format } from "date-fns";
import RecyclingIcon from "@mui/icons-material/Recycling";
import { useTranslation } from "react-i18next";

const maxTagsLength = 20;

const formatDate = (dateString) => {
  const date =
    dateString instanceof Date && !isNaN(dateString)
      ? dateString
      : new Date(dateString);
  return format(date, "dd/MM/yyyy HH:mm");
};

const defaultRenderer = (value, textColor) => (
  <Text me="10px" color={textColor} fontSize="md" fontWeight="400">
    {value || "-"}
  </Text>
);

const DeviceTableContent = ({ page, prepareRow, textColor }) => {
  const { t } = useTranslation();
  const renderers = {
    date: (value, textColor) => (
      <Text me="10px" color={textColor} fontSize="md" fontWeight="400">
        {formatDate(value) || "-"}
      </Text>
    ),
    textList: (value, textColor) => {
      let result;
      if (value.length === 1) {
        return (
          <Tooltip label={result}>
            <Text me="10px" color={textColor} fontSize="md" fontWeight="400">
              {value.name || "-"}
            </Text>
          </Tooltip>
        );
      }
      result = value.map((tag) => tag.name).join(", ");
      if (result.length > maxTagsLength) {
        let truncatedString = result.substring(0, maxTagsLength);
        const lastCommaIndex = truncatedString.lastIndexOf(",");
        return (
          <Tooltip label={result}>
            <Text me="10px" color={textColor} fontSize="md" fontWeight="400">
              {truncatedString.substring(0, lastCommaIndex) + " ..." || "-"}
            </Text>
          </Tooltip>
        );
      } else {
        return (
          <Text me="10px" color={textColor} fontSize="md" fontWeight="400">
            {result || "-"}
          </Text>
        );
      }
    },
    status: (value, textColor) => (
      <Text me="10px" color={textColor} fontSize="md" fontWeight="400">
        {`${t(`pie_chart.dev_is_alive.${value}`)}` || "-"}
      </Text>
    ),
    text: (value, textColor) => (
      <Text me="10px" color={textColor} fontSize="md" fontWeight="400">
        {value || "-"}
      </Text>
    ),
  };
  const formatValue = (cell) => {
    const renderer = renderers[cell.column.type] || defaultRenderer;
    return renderer(cell.value, textColor);
  };

  return (
    <Tbody>
      {page.map((row, index) => {
        prepareRow(row);
        return (
          <Tr {...row.getRowProps()} key={index}>
            {row.cells.map((cell, index) => {
              let data = <Flex align="center">{formatValue(cell)}</Flex>;
              return (
                <Td
                  {...cell.getCellProps()}
                  key={index}
                  fontSize={{ sm: "14px" }}
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor="transparent"
                >
                  {data}
                </Td>
              );
            })}
          </Tr>
        );
      })}
    </Tbody>
  );
};

export default DeviceTableContent;
