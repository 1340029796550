import React from "react";
import {
  Button,
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Portal,
} from "@chakra-ui/react";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import { useTranslation } from "react-i18next";
import ColumnVisibilityToggle from "views/events/components/ColumnVisibilityToggle";


const bgColor = "pcr.200";
const hoverColor = "pcr.100";

export default function ColumnsButton({allColumns, toggleHideColumn, pageType}) {

  const { t } = useTranslation();

  return (
    <Popover>
      <PopoverTrigger>
        <Button bgColor={bgColor} _hover={{ bgColor: hoverColor }}>
          {" "}
          <ViewWeekIcon /> <Text pl={"2px"}>{t("column_button")}</Text>
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent borderColor={"black"}>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverHeader>{t("column_title")}</PopoverHeader>
          <PopoverBody>
            <ColumnVisibilityToggle
              allColumns={allColumns}
              toggleHideColumn={toggleHideColumn}
              pageType = {pageType}
            />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
}
