import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Box, Heading, VStack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import API from "api/API";
import CustomFormControl from "./CustomFormControl";
import { useTranslation } from "react-i18next";
import FormButtons from "components/slider/FormButtons";

const EventForm = ({ eventID, onClose }) => {
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const [readOnly, setReadOnly] = useState(true);

  const methods = useForm({
    defaultValues: data,
  });

  const { t } = useTranslation();

  const onSubmit = (data) => {
    console.log("submit: ");
    console.log(data);
  };

  useEffect(() => {
    if (eventID) {
      API.getEventById(eventID).then((result) => {
        if (result.isSuccess) {
          // setReadOnly(true);
          setData({ ...result.data, notes: result.data.notes || "" });
          methods.reset(result.data);
        } else {
          console.error("failed fetch data");
        }
      });
    } else {
      navigate("/events");
    }
  }, [eventID, methods]);

  const fields = Object.keys(data).map((key) => ({
    id: key,
    name: key,
    type: key === "eventTime" ? "datetime-local" : "text",
    label: key.charAt(0).toUpperCase() + key.slice(1),
    validation: {},
    defaultValue: data[key],
    isTextArea: key === "notes" ? true : false,
  }));

  return (
    <Box p={4}>
      <Box bg="white" p={4}>
        <Heading size="md" pb={"20px"} textAlign={"center"}>
          {t("event_form.handle_event")}
        </Heading>
        <VStack spacing={4} align="stretch">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              {fields.map(
                (field) =>
                  field.name !== "id" && (
                    <CustomFormControl
                      key={field.id}
                      id={field.id}
                      name={field.name}
                      type={field.type}
                      label={field.label}
                      validation={field.validation}
                      defaultValue={field.defaultValue}
                      isTextArea={field.isTextArea}
                      translationPrefix={"event_form"}
                      isReadOnly={readOnly}
                    />
                  )
              )}

              <FormButtons
                onClose={onClose}
                isUpdateForm={!!data}
                setReadOnly={setReadOnly}
                isReadOnly={readOnly}
              />
            </form>
          </FormProvider>
        </VStack>
      </Box>
    </Box>
  );
};

export default EventForm;
